<template>
  <div class="section-two"
    data-observe
    data-classes="fade-in-bottom"
    data-delay="100">

    <div class="left-info">
      <div class="contest-info"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="200">
        <div>
        <p class="text">Total amount available:</p>
        <h1 class="number">$100,000</h1>
        </div>
        <div>
          <p class="text">Winners:</p>
          <h1 class="number">10</h1>
        </div>
      </div>
      <p class="text">Amount towards each winner’s education:</p>
      <h1 class="number">$10,000</h1>
    </div>
    <div class="image-container"
       data-observe
      data-classes="fade-in-bottom"
      data-delay="500">
      <img src="/img/main_section_2_girl.png" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// @ is an alias to /src
export default {
  name: 'SectionTwoHome',
  components: {

  },
  computed: {
    ...mapState(['observer']),
  },
  methods: {
    observeElements() {
      if (!this.observer) {
        return;
      }
      // Observe the rest
      const elements = [...document.querySelectorAll('[data-observe]')];
      elements.forEach((element) => {
        element.classList.add('opacity-0');
        this.observer.observe(element);
      });
    },
  },
  mounted() {
    this.observeElements();
  },
};
</script>
<style lang="scss" scoped>
.section-two{
  display: grid;
  grid-template-columns: auto;
  background: $black-footer;
  padding: 70px 20px 70px 20px;
  margin-bottom: 80%;
  @include respond-to('small'){
    grid-template-columns: 50% 50%;
    column-gap: 0px;
    padding: 58px 48px 0 48px;
    margin-bottom: 20%;
  }
  .left-info{
    display: grid;
    grid-template-columns: auto;
    .contest-info{
      display: grid;
      grid-template-columns: auto;
      @include respond-to('small'){
        grid-template-columns: auto auto;
        column-gap: 58px;
      }
    }
    h1{
      color: white;
      font-family: 'TiemposHeadlineLight';
      font-size: 50px;
      padding: 0 0 40px 0;
      @include respond-to('small'){
        padding: 0 0 58px 0;
      }
    }
    p{
      color: white;
      font-family: 'ModernEra';
      line-height: 20px;
      color: white;
      @include respond-to('small'){
        line-height: 16px;
      }
    }
  }
  .image-container{
    position: relative;
    img{
      width: 100%;
      position: absolute;
      @include respond-to('small'){
        width: 100%;
        position: absolute;
      }
    }
  }
}
</style>
