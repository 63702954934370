<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['initIntObserver']),
  },
  computed: {
    ...mapState(['observer']),
  },
  mounted() {
    this.initIntObserver();
  },
};
</script>

<style lang="scss">
html, body {
  margin: 0 !important;
}
* {
  &::selection {
    background: $hinge-purple;
    color:white;
  }
}
</style>
