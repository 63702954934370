<template>
  <section
    class="application-form"
    data-observe
    data-classes="fade-in-bottom"
    data-delay="250"
  >
    <h1>Application Form</h1>
    <ValidationObserver ref="entryform" tag="form">
      <form @submit.prevent="handleSubmit">
        <label class="section-label"> Personal Information </label>

        <div class="form-group">
          <validation-provider :rules="'required|min:2'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="firstname" class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstname"
                  placeholder="Enter First Name"
                  v-model="payload.firstname">
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required|min:2'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="lastname" class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastname"
                  placeholder="Enter Last Name"
                  v-model="payload.lastname">
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'email|required|singleEntry'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="email" class="form-label">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter Email Address"
                  v-model="payload.email">
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'phone|required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="phone" class="form-label">Phone Number</label>
                <input
                  type="tel"
                  class="form-control phone-input"
                  id="phone"
                  v-mask="'(###) ###-####'"
                  placeholder="(xxx) xxx-xxxx"
                  v-model="payload.phone">
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <div class="city-state-container">
            <div class="city">
              <validation-provider :rules="'required'" v-slot="{ errors }">
                <div class="form-element" :class="{error: errors && errors.length > 0}">
                  <div>
                    <label for="city" class="form-label">City of Residence</label>
                    <input
                      type="text"
                      class="form-control phone-input"
                      id="city"
                      placeholder="Enter City"
                      v-model="payload.city">
                  </div>
                    <label
                      lang="en"
                      class="error-message"
                      aria-live="polite"
                      v-show="errors && errors.length > 0"
                    >
                      {{ errors[0] }}
                    </label>
                  </div>
              </validation-provider>
            </div>
            <div class="state">
              <validation-provider :rules="'required'" v-slot="{ errors }">
                <div class="form-element" :class="{error: errors && errors.length > 0}">
                  <div>
                    <label for="state" class="form-label">State</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name=""
                      id="state"
                      v-model="payload.state"
                      :class="{grey: payload.state === ''}"
                      >
                      <option selected disabled value="">Select Options</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                      <option value="AS">American Samoa</option>
                      <option value="GU">Guam</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="UM">United States Minor Outlying Islands</option>
                      <option value="VI">Virgin Islands</option>
                    </select>
                  </div>
                    <label
                      lang="en"
                      class="error-message"
                      aria-live="polite"
                      v-show="errors && errors.length > 0"
                    >
                      {{ errors[0] }}
                    </label>
                </div>
              </validation-provider>
            </div>
          </div>

          <div class="pronouns-birthday-container">
            <div class="pronouns">
              <validation-provider :rules="''" v-slot="{ errors }">
                <div class="form-element" :class="{error: errors && errors.length > 0}">
                  <div>
                    <label for="pronouns" class="form-label">Pronouns (optional)</label>
                    <!-- <select
                      class="form-select"
                      aria-label="Default select example"
                      name=""
                      id="pronouns"
                      v-model="payload.pronouns"
                      :class="{grey: payload.state === ''}"
                      >
                      <option selected disabled value="">Select Options</option>
                    </select> -->
                    <input
                      type="text"
                      class="form-control"
                      id="pronouns"
                      placeholder="Pronouns"
                      v-model="payload.pronouns">
                  </div>
                    <label
                      lang="en"
                      class="error-message"
                      aria-live="polite"
                      v-show="errors && errors.length > 0"
                    >
                      {{ errors[0] }}
                    </label>
                </div>
              </validation-provider>
            </div>
              <validation-provider :rules="'required|18+'" v-slot="{ errors }">
                <div class="form-element" :class="{error: errors && errors.length > 0}">
                  <div>
                    <label for="dob" class="form-label">Birthday</label>
                    <input
                      type="date"
                      class="form-control"
                      id="dob"
                      v-model="payload.dob">
                  </div>
                    <label
                      lang="en"
                      class="error-message"
                      aria-live="polite"
                      v-show="errors && errors.length > 0"
                    >
                      {{ errors[0] }}
                    </label>
                </div>
              </validation-provider>
          </div>
        </div>

        <label class="section-label"> Education Status </label>

        <div class="form-group">

          <validation-provider :rules="'required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="school" class="form-label">School</label>
                <input
                  type="text"
                  class="form-control education-input"
                  id="school"
                  v-model="payload.school"
                  placeholder="Enter School">
              </div>
              <label
                lang="en"
                class="error-message"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="schooladdress" class="form-label">School Address</label>
                <input
                  type="text"
                  class="form-control education-input"
                  id="schooladdress"
                  v-model="payload.schooladdress"
                  placeholder="Enter School Address">
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="enrollment" class="form-label">Enrollment Status</label>
                <select
                  class="form-select education-input"
                  aria-label="Default select example"
                  name=""
                  id="enrollment"
                  v-model="payload.enrollment"
                  :class="{grey: payload.enrollment === ''}"
                >
                  <option selected disabled value="">Select Options</option>
                  <option value="Full-time student">Full-time student</option>
                  <option value="Part-time student">Part-time student</option>
                </select>
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="degree" class="form-label">Type of Degree</label>
                <select
                  class="form-select education-input"
                  aria-label="Default select example"
                  name=""
                  id="degree"
                  v-model="payload.degree"
                  :class="{grey: payload.degree === ''}"
                >
                  <option selected disabled value="">Select Options</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Certification program">Certification program</option>
                </select>
              </div>
              <label
                lang="en"
                class="error-message"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>
          <div class="enrollment-graduation-container">
            <validation-provider :rules="'required|length:4|numeric'" v-slot="{ errors }">
              <div class="form-element" :class="{error: errors && errors.length > 0}">
                <div>
                  <label for="enrollmentyear" class="form-label">Year of Enrollment</label>
                  <input
                    type="text"
                    class="form-control education-short-input enrollment-input"
                    id="enrollmentyear"
                    v-mask="'####'"
                    v-model="payload.enrollmentyear"
                    placeholder="Enter Here">
                </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
              </div>
            </validation-provider>

            <validation-provider :rules="'required|length:4|numeric'" v-slot="{ errors }">
              <div class="form-element" :class="{error: errors && errors.length > 0}">
                <div>
                  <label for="graduationdate" class="form-label">Expected Graduation Year</label>
                  <!-- <input
                    type="text"
                    class="form-control education-short-input enrollment-input"
                    id="graduationdate"
                    v-mask="'##/##/####'"
                    v-model="payload.graduationdate"
                    placeholder="Enter Here"> -->
                    <input
                      type="text"
                      class="form-control"
                      id="graduationdate"
                      v-mask="'####'"
                      placeholder="Enter Here"
                      v-model="payload.graduationdate">
                </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
              </div>
            </validation-provider>
          </div>

          <validation-provider :rules="'required'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="fieldstudy" class="form-label">Major or Field of Study</label>
                <input
                  type="text"
                  class="form-control education-input"
                  id="fieldstudy"
                  v-model="payload.fieldstudy"
                  placeholder="Enter Major or Field of Study">
                </div>
              <label
                lang="en"
                class="error-message"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>
        </div>

        <label class="section-label"> Additional Information </label>
        <p>
          Please provide your relevant experience and/or knowledge surrounding
          the mental health and wellness of LGBTQIA+ and BIPOC communities
          below.
        </p>

        <div class="form-group">
          <validation-provider :rules="'required|max_words:100'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="professionalexperience" class="form-label">
                  Relevant Professional Experience</label>
                <textarea
                  placeholder="1 - 2 sentences"
                  class="form-control"
                  id="professionalexperience"
                  v-model="payload.professionalexperience"
                  rows="2">
                </textarea>
                <p class="wordcount">{{wordCount(payload.professionalexperience)}}/100</p>
              </div>
              <label
                lang="en"
                class="error-area"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required|max_words:100'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="volunteerexperience" class="form-label">
                  Relevant Volunteer Experience</label>
                <textarea
                  placeholder="1 - 2 sentences"
                  class="form-control"
                  id="volunteerexperience"
                  v-model="payload.volunteerexperience"
                  rows="2">
                </textarea>
                <p class="wordcount">{{wordCount(payload.volunteerexperience)}}/100</p>
              </div>
              <label
                lang="en"
                class="error-area"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required|max_words:100'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="schoolexperience" class="form-label">
                  Relevant Courses or School Experience</label>
                <textarea
                  placeholder="1 - 2 sentences"
                  class="form-control"
                  id="schoolexperience"
                  v-model="payload.schoolexperience"
                  rows="2">
                </textarea>
                <p class="wordcount">{{wordCount(payload.schoolexperience)}}/100</p>
              </div>
              <label
                lang="en"
                class="error-area"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>

          <validation-provider :rules="'required|max_words:100'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div>
                <label for="learningexperience" class="form-label">
                  Relevant Learning Experiences</label>
                <textarea
                  placeholder="1 - 2 sentences"
                  class="form-control"
                  id="learningexperience"
                  v-model="payload.learningexperience"
                  rows="2">
                </textarea>
                <p class="wordcount">{{wordCount(payload.learningexperience)}}/100</p>
              </div>
              <label
                lang="en"
                class="error-area"
                aria-live="polite"
                v-show="errors && errors.length > 0"
              >
                {{ errors[0] }}
              </label>
            </div>
          </validation-provider>
        </div>

        <label class="section-label"> Essay Questions </label>
        <p class="prompt-text">
          Privacy is important to us. Keep in mind that your responses could be
          promoted, so don’t share any information that you wouldn’t want others
          to know (like private health or financial information). If you discuss
          other individual(s), be sure to change or remove any personal or
          health information that could identify them.
        </p>

        <div class="form-group" id="prompt">
          <div class="prompt-form-container">
            <validation-provider :rules="'required|max_words:200'" v-slot="{ errors }"
              class="promt-container">
              <div class="form-element" :class="{error: errors && errors.length > 0}">
                <div>
                  <label for="prompt1" class="form-label prompt">
                    Prompt #1:</label>
                  <p>
                    Please share your insights/perspectives on the mental health and
                    wellness needs of LGBTQIA+ and/or BIPOC communities.
                  </p>
                  <textarea
                    placeholder="Enter up to 200 words"
                    class="form-control"
                    id="prompt1"
                    v-model="payload.prompt1"
                    rows="5">
                  </textarea>
                  <p class="wordcount">{{wordCount(payload.prompt1)}}/200</p>
                </div>
                <label
                  lang="en"
                  class="error-area"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
              </div>
            </validation-provider>

            <validation-provider :rules="'required|max_words:400'" v-slot="{ errors }"
              class="promt-container">
              <div class="form-element" :class="{error: errors && errors.length > 0}">
                <div>
                  <label for="prompt2" class="form-label prompt">
                    Prompt #2:</label>
                  <p>
                    How do you plan to invest in stronger mental health support for
                    BIPOC and LGBTQIA+ folks throughout your career?
                  </p>
                  <textarea
                    placeholder="Enter up to 400 words"
                    class="form-control"
                    id="prompt2"
                    v-model="payload.prompt2"
                    rows="5">
                  </textarea>
                  <p class="wordcount">{{wordCount(payload.prompt2)}}/400</p>
                </div>
                <label
                  lang="en"
                  class="error-area"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                >
                  {{ errors[0] }}
                </label>
              </div>
            </validation-provider>
          </div>

          <validation-provider :rules="'requiredcheckbox'" v-slot="{ errors }">
            <div class="form-element" :class="{error: errors && errors.length > 0}">
              <div class="form-check check">
                <input
                  type="checkbox"
                  value=""
                  id="terms"
                  v-model="payload.terms">
                <label class="form-check-label" for="terms">
                  I confirm I meet the Eligibility Requirements and have read and
                  agree to the <router-link
                  @click.native="trackButtonClick('optin-button-official-rules')"
                  to="/OfficialRules"
                  target="_blank">Official Rules</router-link>, Sponsor’s
                  <a
                  @click="trackButtonClick('optin-button-hinge-terms')"
                  target="_blank" href="https://www.hinge.co/terms/">Terms of Use</a> and
                  <a
                  @click="trackButtonClick('optin-button-hinge-privacy')"
                  target="_blank" href="https://www.hinge.co/privacy/">Privacy Policy</a> .
                </label>
              </div>
                <label
                  lang="en"
                  class="error-message"
                  aria-live="polite"
                  v-show="errors && errors.length > 0"
                  >
                    {{ errors[0] }}
                </label>
            </div>
          </validation-provider>
        </div>
        <button :disabled="disableSubmit" type="submit">{{disableSubmit ? '...' : 'Enter'}}</button>
      </form>
    </ValidationObserver>
    <summary-rules-form></summary-rules-form>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import Api from '@/lib/Api';
import SummaryRulesForm from '../Global/SummaryRulesForm.vue';
// import { mapState } from 'vuex';

export default {
  data() {
    return {
      disableSubmit: false,
      observer: null,
      payload: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        pronouns: '',
        city: '',
        state: '',
        dob: '',
        school: '',
        schooladdress: '',
        enrollment: '',
        degree: '',
        enrollmentyear: '',
        graduationdate: '',
        fieldstudy: '',
        professionalexperience: '',
        volunteerexperience: '',
        schoolexperience: '',
        learningexperience: '',
        prompt1: '',
        prompt2: '',
        terms: false,
        env: process.env.VUE_APP_ENV ?? '',
      },
      // payload: {
      //   firstname: 'Eduardo',
      //   lastname: 'Moratalla',
      //   email: 'eduardo@hellodebrain.com',
      //   phone: '(555) 666-6232',
      //   pronouns: 'Mr',
      //   city: 'New York',
      //   state: 'NY',
      //   dob: '1989-10-31',
      //   school: 'School',
      //   schooladdress: 'School Address',
      //   enrollment: 'enrollment',
      //   degree: 'Degree',
      //   enrollmentyear: '2000',
      //   graduationdate: '2020-10-06',
      //   fieldstudy: 'Field Study',
      //   professionalexperience: 'professionalexperience',
      //   volunteerexperience: 'volunteerexperience',
      //   schoolexperience: 'schoolexperience',
      //   learningexperience: 'learningexperience',
      //   prompt1: 'prompt1',
      //   prompt2: 'prompt2',
      //   terms: true,
      //   env: process.env.VUE_APP_ENV ?? '',
      // },
    };
  },
  components: {
    ValidationObserver,
    SummaryRulesForm,
  },
  computed: {
    // ...mapState(['observer']),
  },
  methods: {
    wordCount(string) {
      if (typeof (string) !== 'string' || string.trim().length === 0) {
        return 0;
      }
      const wordCount = string.match(/(\w+)/g).length ?? 0;
      return wordCount;
    },
    trackButtonClick(event) {
      this.$gtag.event(`${event.toLowerCase().trim().replaceAll(' ', '-')}`, {
        event_category: 'hinge-advocates',
        event_label: `${event.toLowerCase().trim().replaceAll(' ', '-')}`,
        value: 0,
      });
    },
    async handleSubmit() {
      this.disableSubmit = true;
      const isValid = await this.$refs.entryform.validate();
      if (!isValid) {
        // ABORT!!
        // this.$message.error('You are missing a required field.');
        // eslint-disable-next-line no-alert
        // alert('Invalid');
        this.disableSubmit = false;
        this.$toasted.error('Please Correct form inputs');
        return;
      }
      let success = false;

      try {
        this.$gtag.event('form-submitted', {
          event_category: 'hinge-advocates',
          event_label: 'form-submitted',
          value: 0,
        });
        // Disable button
        this.disableSubmit = true;
        const result = await Api.submitForm(this.payload);
        const resultTool = await Api.submitFormTool(this.payload);
        console.log(resultTool);

        if (!result || result.success !== true) {
          // eslint-disable-next-line no-alert
          // alert(`Error: ${result.message}`);
          this.$toasted.error(`Error: ${result.message}`);
          this.disableSubmit = true;
          return;
        }
        // this.$toasted.success('Entry submitted correctly');
        success = true;
        // this.$router.push({ path: '/ConfirmationPage' }, window.scrollTo({ top: 0 }));
      } catch (error) {
        // Handle Exception and show alert
        // eslint-disable-next-line no-alert
        // alert(`Error: ${error.message}`);
        success = false;
        this.$toasted.error(`Error: ${error.message}`);
      } finally {
        // Enable button
        this.disableSubmit = false;
        if (success === true) {
          this.$router.push({ path: '/ConfirmationPage' }, window.scrollTo({ top: 0 }));
        }
      }
    },
    initIntObserver() {
      const options = {
        rootMargin: '0px',
        threshold: 0.00001,
      };

      const onObserve = (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }
          const id = entry.target.getAttribute('data-ref') ?? null;
          // console.log(id, entry.target);
          const delay = +entry.target?.getAttribute('data-delay') ?? 0;
          const classesString = entry.target?.getAttribute('data-classes') ?? '';

          setTimeout(() => {
            const classes = classesString.split(' ');
            classes.forEach((cl) => {
              entry.target.classList.add(cl);
              const animate = cl === 'animate';
              if (animate && id) {
                this[id] = true;
              }
              if (cl === 'fade-in-bottom') {
                entry.target.classList.remove('opacity-0');
              }
            });
          }, delay);
          observer.unobserve(entry.target);
        });
      };

      const observer = new IntersectionObserver(onObserve, options);
      this.observer = observer;
    },
    destroyObserver() {
      this.observer.disconnect();
      this.observer = null;
    },
    observeElements() {
      if (!this.observer) {
        return;
      }
      // Observe the rest
      const elements = [...document.querySelectorAll('[data-observe]')];
      elements.forEach((element) => {
        element.classList.add('opacity-0');
        this.observer.observe(element);
      });
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // setTimeout(() => {
      //   window.scrollTo({ top: 0, behavior: 'smooth' });
      // }, 50);
    },
  },
  mounted() {
    this.initIntObserver();
    this.observeElements();
  },
  beforeDestroy() {
    this.destroyObserver();
  },

};
</script>

<style lang="scss" scoped>
section.application-form {
  padding: 0 20px;
  @include respond-to("small") {
    padding: 0 48px;
  }
  margin: 0 0 62px 0;

  h1{
    margin-bottom: 40px;
    @include respond-to('small'){
      margin-bottom: 52px;
    }
  }
  .wordcount{
    color: grey;
    font-size: smaller;
    right: 0;
    bottom: -30px;
    margin-left: 90%;
    //margin-right: 40px;
    @include respond-to("small") {
      //margin-right: 40px;
      margin-left: 80%;
    }
  }
  .form-element {

    position: relative;
    $error-color: tomato;
    &.error {
      label {
        color: $error-color;
        &.error-message {
          padding-bottom: 10px;
        }
        &.error-area {
          position: absolute;
          margin-top: -25px;
          padding-bottom: 10px;
        }
      }
      input {
        border-color:  $error-color;
        color: $error-color;
      }
      select {
        border-color:  $error-color;
        color: $error-color;
      }
      textarea {
        border-color:  $error-color;
        color: $error-color;

      }
      a{
        color: $error-color;
      }
      .wordcount{
        color: $error-color;
      }
    }
  }

  .section-label {
    display: block;
    color: $hinge-purple;
    font-weight: bold;
    font-family: "ModernEra";
    font-size: 16px;
    @include respond-to('small'){
      margin-bottom: 16px;
    }
  }

  .form-group {
    display: grid;
    grid-template-columns: auto;
    margin: 24px 0 48px 0;
    @include respond-to("small") {
      grid-template-columns: 50% 50%;
      margin: 36px 0 52px 0;
    }
    .city-state-container {
      display: grid;
      grid-template-columns: 46.4% 46.4%;
      column-gap: 25px;
      @include respond-to('small'){
        grid-template-columns: 47% 47%;
        width: 90%;
      }
      select#state{
        @include respond-to('small'){
          width: 100%;
          }
      }
      input#city{
        @include respond-to('small'){
          width: 100%;
          }
      }
    }
    .pronouns-birthday-container {
      display: grid;
      grid-template-columns: 46.4% 46.4%;
      column-gap: 25px;
      @include respond-to('small'){
        width: 90%;
        grid-template-columns: 47% 47%;
      }
      input#dob{
        @include respond-to('small'){
          width: 100%;
        }
      }
      input#pronouns{
        @include respond-to('small'){
          width: 100%;
        }
      }
    }
    .textarea{
      width: 100%;
    }
  }
  .form-input-container {
    display: grid;
    grid-template-columns: auto;
  }
  input {
    margin: 5px 0 5px 1px;
    //min-height: 22px;
    width: 100%;
    border-radius: 8px;
    //padding: 5px 15px;
    outline: none;
    border: 1px solid rgba($hinge-black, 0.5);
    transition: none ;
    font-size: 16px;

    &::placeholder {
      font-family: ModernEra;
      color: rgba($hinge-black, 0.3);
    }
    @include respond-to('small'){
      width: 90%;
    }
  }
  select {
    // margin: 5px 0 20px 1px;
    // height: 22px;
    // width: 100%;
    // border-radius: 5px;

    margin: 5px 0 5px 1px;
    min-height: 22px;
    width: 100%;
    border-radius: 8px;
    outline: none;
    border: 1px solid rgba($hinge-black, 0.5);
    font-size: 16px;

    &::placeholder {
      font-family: ModernEra;
      color: rgba($hinge-black, 0.3);
    }
    &.grey {
      color: rgba($hinge-black, 0.3);
    }
    @include respond-to('small'){
      width: 90%;
    }
  }
  textarea {
    margin: 6px 0 0 1px;
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    border-color: $black;
    font-size: 16px;
    @include respond-to('small'){
      width: 90%;
    }
  }
  .check {
    display: flex;
    width: 100%;
    margin-top:20px ;
    margin-bottom: 5px;
    font-size: 16px;
    input {
      width: 100px;
      @include respond-to('small'){
        margin-top: 5px;
        width: 20px;
      }
    }
    label {
      margin-left: 10px;
      font-size: 16px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  button {
    width: 30%;
    border-radius: 8px;
    margin: 32px 0;
    background-color: $black;
    color: white;
    height: 35px;
    font-size: 16px;
    @include respond-to('small'){
      width: 100px;
      border-radius: 20px;
    }
  }
  a {
    color: $black;
    font-size: 16px;
  }
  p{
    font-size: 16px;
    span{
    font-weight: bold;
    font-size: 16px;
    }
  }
  .promt-container{
    width: 100%;
    p{
      width: 100%;
      @include respond-to('small'){
        width: 95%;
      }
    }
  }
  .prompt-text{
    margin: 20px 0 10px 0;
    font-size: 16px;
  }
  .enrollment-graduation-container{
    display: grid;
    grid-template-columns: auto;
    @include respond-to('small'){
      width: 90%;
    }
    input#graduationdate{
      width: 100%;
    }
    input#enrollmentyear{
      width: 100%;
    }
  }
  .prompt-form-container{
    display: grid;
    grid-template-columns: auto;
    @include respond-to('small'){
      grid-template-columns: 50% 50%;
    }
  }
  #prompt{
    grid-template-columns: auto;
    margin-bottom: 0;
  }
  .form-label{
    font-size: 16px;
  }
  .prompt{
    font-size: 30px;
    font-family: 'TiemposHeadline';
    margin: 20px 0 0 0;
  }
}
</style>
