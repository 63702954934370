import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import OfficialRules from '@/views/OfficialRules.vue';
import FrecuentlyQuestions from '@/views/FrecuentlyQuestions.vue';
import ConfirmationPage from '@/views/ConfirmationPage.vue';
import CampaignClosed from '@/views/CampaignClosed.vue';
import ComingSoonPage from '@/views/ComingSoonPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/OfficialRules',
    name: 'OfficialRules',
    component: OfficialRules,
  },
  {
    path: '/faq',
    name: 'FrecuentlyQuestions',
    component: FrecuentlyQuestions,
  },
  {
    path: '/ConfirmationPage',
    name: 'ConfirmationPage',
    component: ConfirmationPage,
  },
  {
    path: '/CampaignClosed',
    name: 'CampaignClosed',
    component: CampaignClosed,
  },
  {
    path: '/coming-soon',
    name: 'ComingSoonPage',
    component: ComingSoonPage,
  },
];
// const router = new VueRouter({
//   history: (process.env.BASE_URL),
//   routes,
// });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // const env = process.env.VUE_APP_ENV;

//   const checkAuthorized = () => {
//     let authorized = false;
//     // check session storage
//     const storageBypass = sessionStorage.getItem('bypass') ?? 'false';
//     if (storageBypass == null || storageBypass === 'false') {
//       // check url
//       authorized = to.query?.bypass === 'true' ?? false;
//       if (authorized) {
//         sessionStorage.setItem('bypass', 'true');
//       }
//       return authorized;
//     }
//     authorized = JSON.parse(storageBypass) === true;

//     return authorized;
//   };

//   const isAuthorized = checkAuthorized();

//   if (
//     to.name.toLowerCase() === 'comingsoonpage'
//     || to.name.toLowerCase() === 'officialrules'
//     || to.name.toLowerCase() === 'frecuentlyquestions'
//     // || env !== 'production'
//     || isAuthorized
//   ) {
//     return next();
//   }
//   return next({ name: 'ComingSoonPage' });
// });

export default router;
