<template>
  <section
    class="section-three"
    data-observe
    data-classes="fade-in-bottom"
    data-delay="100"
  >
    <h1 class="title">Who we’re looking for.</h1>
    <p
      class="instructions"
      data-observe
      data-classes="fade-in-bottom"
      data-delay="150"
    >
      To qualify, you must meet the following Eligibility Requirements:
    </p>
    <div class="requirements-container">
      <div
        class="requirement one"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="250"
      >
        <h2>01</h2>
        <p>
          A current student working towards an undergraduate or graduate degree
          and/or certificate program in a field related to mental health or
          mental wellness.
        </p>
      </div>
      <div
        class="requirement two"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="550"
      >
        <h2>02</h2>
        <p>
          Able to demonstrate a commitment to supporting mental health for BIPOC
          and/or LGBTQIA+ individuals.
        </p>
      </div>
      <div
        class="requirement three"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="850"
      >
        <h2>03</h2>
        <p>A United States resident that is 18+.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  computed: {
    ...mapState(['observer']),
  },
  methods: {
    observeElements() {
      if (!this.observer) {
        return;
      }
      // Observe the rest
      const elements = [...document.querySelectorAll('[data-observe]')];
      elements.forEach((element) => {
        element.classList.add('opacity-0');
        this.observer.observe(element);
      });
    },
  },
  mounted() {
    this.observeElements();
  },
};
</script>

<style lang="scss" scoped>
section.section-three {
  padding: 86px 18px;

  @include respond-to("small") {
    padding: 8% 48px 8% 48px;
  }
  // @include respond-to('medium') {
  //   padding-left: 12%;
  // }

  h1.title {
    font-size: 9.3vw;
    line-height: 70px;
    margin: 0 0 40px 0;

    @include respond-to("small") {
      font-size: 45px;
    }
  }

  p {
    margin-bottom: 40px;
    @include respond-to("small") {
      margin-bottom: 0;
    }
    .instructions {
      margin-bottom: 40px;
    }
  }
  .requirements-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    column-gap: 30px;
    margin: 50px 0px 0 0px;
    // padding: 0 25px;

    @include respond-to("xsmall") {
      grid-template-columns: repeat(3, 1fr);
    }
    h2 {
      margin-bottom: 28px;
      @include respond-to("small") {
        margin-bottom: 40px;
        font-size: 58px;
      }
    }
  }
}
</style>
