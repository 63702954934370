<template>
  <div class="hinge-mental-header">
    <img
      @click="handleLogoClick"
      class="logo"
      src="/img/hinge_mental_health_header_logo.svg"
      alt="Hinge logo"
    >
    <!-- <img class="title" src="/img/hinge_mental_health_header_title.svg" alt=""> -->
    <img class="title" src="/img/main_section_1_title_animated.gif" alt="">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HingeMentalHealthHeader',
  components: {

  },
  methods: {
    handleLogoClick() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style lang="scss" scoped>
.hinge-mental-header{
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0;
  width: 100%;
  padding: 0 18px;
  @include respond-to('small'){
    flex-direction: row;
    justify-content: space-between;
    padding: 0 48px;
    max-width: 1000px;
    margin: 50px auto 0 auto;
  }
  .logo{
    width: 100px;
    padding: 0 0 0 37px;
    transition: all 125ms;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    @include respond-to('small'){
      padding: 0 0 70px 0;
    }
  }
  .title{
    //padding: 0 0 0 10px;
    @include respond-to('small'){
      padding: 0 0 0 0;
    }
  }
  img{
    width: 250px;
    //margin: 0 0 20px 0;
    @include respond-to('small'){
      margin: 0 0 0 0;
    }
  }
}
</style>
