<template>
  <section class="section-one">
    <div class="row row-1">
      <div class="column align-items-center">
        <div class="d-column w-100 title">
          <!-- <img
            src="/img/main_section_1_title.svg"
            alt="Mental Health Advocates of Tomorrow"
          /> -->
          <img
            @click="handleLogoClick"
            class="logo"
            src="/img/hinge_mental_health_header_logo.svg"
            alt="Hinge logo"
          >
          <img
            src="/img/main_section_1_title_animated.gif"
            class="mental-img"
            alt="Mental Health Advocates of Tomorrow"
            data-observe
            data-classes="fade-in-bottom"
          />
          <!-- <hinge-mental-health-advocates
            class="opacity-0"
            v-scroll-show='{active: "animate opacity-1 fade-in-bottom", delay: 500}'
            :animate="animate1"
            ref="animate1"
            data-ref="animate1"
          /> -->
          <!-- <hinge-mental-health-advocates
            class="opacity-0"
            :animate="animate1"
            ref="animate1"
            data-ref="animate1"
            data-classes="animate opacity-1 fade-in-bottom"
            data-delay="200"
          /> -->
          <img
            class="hide-mobile arrow-down arrow-desktop"
            src="/img/main_section_1_arrow_down.svg"
            alt="Mental Health Advocates of Tomorrow"
            data-observe
            data-classes="animate opacity-1 fade-in-bottom"
          />
        </div>
      </div>
      <div class="column">
        <div class="d-column">
          <!-- <img
            class="photos opacity-0"
            v-scroll-show='{active: "opacity-1 fade-in-bottom", delay: 500}'
            src="/img/main_section_1_photos.png"
            alt="Mental Health Advocates of Tomorrow"
          /> -->
          <img
            class="photos"
            src="/img/main_section_1_photos.png"
            alt="Mental Health Advocates of Tomorrow"
            data-observe
            data-classes="fade-in-bottom"
            data-delay="100"
          />
          <img
            class="show-mobile arrow-down"
            src="/img/main_section_1_arrow_down.svg"
            alt="Mental Health Advocates of Tomorrow"
          />
        </div>
      </div>
    </div>
    <div class="row row-2">
      <div class="column title"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="200"
      >
        <!-- <img
          src="/img/main_section_1_shared.svg"
          alt="Shared Experience is Powerful"
        > -->
        <shared-experience
          class="opacity-0"
          v-scroll-show='{active: "animate opacity-1 fade-in-bottom", delay: 500}'
          :animate="animate3"
          ref="animate3"
          data-ref="animate3"
          data-observe
          data-classes="animate opacity-1 fade-in-bottom"
          data-delay="250"
        />
      </div>
      <div class="column"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="200"
      >
        <p class="p-1">
          At Hinge, we know that how we take care of our mental wellness
          sets the foundation for how we make meaningful connections.
        </p>
        <p class="p-2">
          But for LGBTQIA+ and BIPOC communities, therapists and counselors
          who truly understand their needs, experiences, and perspectives
          are few and far between.
        </p>
      </div>
    </div>
    <div class="row row-3">
      <div class="column title"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="200"
      >
      <h1>Introducing: Mental Health Advocates of Tomorrow</h1>
        <!-- <img
          src="/img/main_section_1_introducing.svg"
          alt="Introducing: Mental Health Advocates of Tomorrow"
        > -->
      </div>
      <div class="column"></div>
    </div>
    <div class="row row-4 arrow-container">
      <div class="arrow column">
        <!-- <img
          src="/img/main_section_1_arrow_down2.svg"
          alt="Arrow"
          class="arrow"
        > -->
        <!-- <arrow-down
          class="arrow opacity-0"
          v-scroll-show='{active: "animate opacity-1 fade-in-bottom", delay: 500}'
          :animate="animate2"
          ref="animate2"
          data-ref="animate2"
        /> -->
        <arrow-down
          class="arrow opacity-0"
          v-scroll-show='{active: "animate opacity-1 fade-in-bottom", delay: 500}'
          :animate="animate2"
          ref="animate2"
          data-ref="animate2"
          data-classes="animate opacity-1 fade-in-bottom"
          data-delay="250"
        />
      </div>
    </div>
    <div class="row row-5">
      <div class="column title">
      </div>
      <div class="column"
        data-observe
        data-classes="fade-in-bottom"
        data-delay="200"
      >
        <p>
          The Mental Health Advocates of Tomorrow
          initiative gives financial assistance to aspiring
          therapists and counselors who will support
          LGBTQIA+ and BIPOC daters on Hinge and beyond in everyday life.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
// import HingeMentalHealthAdvocates from '@/components/Global/HingeMentalHealthAdvocates.vue';
import ArrowDown from '@/components/Global/ArrowDown.vue';
import SharedExperience from '@/components/Global/SharedExperience.vue';

export default {
  components: {
    // HingeMentalHealthAdvocates,
    ArrowDown,
    SharedExperience,
  },
  data() {
    return {
      observer: null,
      intersectionObserver: null,
      animate1: false,
      animate2: false,
      animate3: false,
    };
  },
  methods: {
    handleLogoClick() {
      this.$router.push({ path: '/' });
    },
    initIntObserver() {
      // const { animate1, animate2 } = this.$refs;
      const { animate2, animate3 } = this.$refs;
      const { onObserve } = this;
      const options = {
        rootMargin: '0px',
        threshold: 1.0,
      };

      const observer = new IntersectionObserver(onObserve, options);
      this.intersectionObserver = observer;
      // window.obs = observer;
      // observer.observe(animate1.$el);
      observer.observe(animate2.$el);
      observer.observe(animate3.$el);

      // Observe the rest
      const elements = [...document.querySelectorAll('[data-observe]')];
      elements.forEach((element) => {
        element.classList.add('opacity-0');
        observer.observe(element);
      });
    },
    onObserve(entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        const id = entry.target.getAttribute('data-ref') ?? null;
        // console.log(id, entry.target);
        const delay = +entry.target?.getAttribute('data-delay') ?? 0;
        const classesString = entry.target?.getAttribute('data-classes') ?? '';

        setTimeout(() => {
          const classes = classesString.split(' ');
          classes.forEach((cl) => {
            entry.target.classList.add(cl);
            const animate = cl === 'animate';
            if (animate && id) {
              this[id] = true;
            }
            if (cl === 'fade-in-bottom') {
              entry.target.classList.remove('opacity-0');
            }
          });
        }, delay);
        observer.unobserve(entry.target);
      });
    },
    initObserver() {
      const { onClassChange } = this;
      this.observer = new MutationObserver((mutations) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName);
          this.$nextTick(() => {
            onClassChange(m.target, newValue, m.oldValue);
          });
        }
      });
      const { animate1, animate2 } = this.$refs;

      this.observer.observe(animate1.$el, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      });
      this.observer.observe(animate2.$el, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      });
    },
    onClassChange(newValue) {
      // console.log(newValue);
      if (!newValue) {
        return;
      }
      const dataId = newValue.getAttribute('data-ref');
      const animate = newValue.classList.contains('animate');
      if (animate) {
        // console.log(dataId, animate);
        // debugger;
        this[dataId] = true;
      }
    },
  },
  mounted() {
    this.initIntObserver();
    // this.initObserver();
  },
  beforeDestroy() {
    // this.observer.disconnect();
    this.intersectionObserver.disconnect();
  },
};
</script>
<style lang="scss" scoped>
section.section-one {
  display: flex;
  flex-direction: column;
  .row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-flow: row wrap;
    padding: 72px 18px;

    @include respond-to('small') {
      grid-template-columns: repeat(2, 1fr);
      padding: 80px 48px;
    }

    .column {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      // align-items: center;
      align-items: baseline;
      // border: 1px solid;
      img {
        width: 110%;
        @include respond-to('small'){
          width: 150%;
        }
        @include respond-to('medium'){
          width: 130%;
        }
      }
      .logo{
          width: 70px;
          transition: all 125ms;
          margin:0 0 0 38px;

          &:hover {
            opacity: 0.8;
            cursor: pointer;
          }
          @include respond-to('small'){
            margin: 0px 0 0 0;
            width: 80px;
          }
          @include respond-to('medium'){
            margin: 0px 0 0 0;
            width: 90px;
          }
        }

      &.title {
        margin-bottom: 20px;
        @include respond-to('small') {
          margin-bottom: 0;
          //padding-left: 10%;
        }
        @include respond-to('medium') {
          margin-bottom: 0;
          //padding-left: 12%;
        }
      }

      p {
        width: 100%;
        margin-left: 72px;
        @include respond-to('small') {
          width: auto;
          margin-left: 0;
        }
      }
    }
  }

  .arrow-container {
    padding: 0;
    justify-content: center;
    grid-template-columns: 1fr;
    .arrow {
      height: 90px;
      // height: 120px;
      transform: scaleX(-1);
      transform: rotate(5deg);
      @include respond-to('small') {
        margin-bottom: 0;
        transform: scaleX(1);
        transform: rotateY(180deg);
        height: 120px;
      }
    }
  }

  .row-1 {
    @include respond-to('small'){
      column-gap: 96px;
      padding: 0px 48px;
    }
    .title {
      padding-left: 0;
      @include respond-to('small'){
        margin: 40px 0 150px 0;
      }
    }
  }

  .row-2{
    column-gap: 96px;
    .p-1{
      margin-top: 0px !important;
      @include respond-to('small'){
        margin-top: 28px;
      }
    }
    .p-2{
      margin-top: 28px;
      padding: 4px !important;
    }
  }
  .row-3 {
    padding-bottom: 0;
    @include respond-to('small') {
      padding-bottom: 0;
    }
  }
  .row-4 {
    .column {
      justify-content: center;
    }
  }
  .row-5 {
    padding-top: 0;
    @include respond-to('small') {
      padding-top: 50px;
    }
  }

  img.photos {
    //width: 100% !important;
    max-width: 100%;
    margin: 18px 0 48px 0;
    @include respond-to('small') {
      margin: 0 0 0 0;
      max-width: 110%;
    }
  }
  img.arrow-down {
    width: 30px !important;
  }
  img.arrow-desktop {
    margin: 25px 0 0 70px;
    width: 25px !important;
    @include respond-to('small'){
      margin: 0 0 0 0;
    }
  }
  .mental-img{
    margin-top: -40px;
    margin-left: -18px;
    @include respond-to('small'){
      margin-left: -62px;
    }
    @include respond-to('medium'){
      margin-left: -75px;
    }
  }
}
</style>
