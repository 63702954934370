<template>
  <p class="summary-rules-form">
    <span>No Purchase Necessary.</span> Ends 11:59 p.m. ET on 6/17/22. Open to
    legal residents of the 50 U.S. & D.C., 18+, who are current students in the
    mental health field at a qualifying Post-Secondary School who demonstrate a
    commitment to supporting LGBTQIA+ and/or BIPOC communities. Finalists
    notified on/about June 18, 2022 and must supplement entry by submitting
    video. Finalists must also agree to background check. Prizes subject to
    applicable taxes. See
    <router-link
      @click.native="trackButtonClick('legal-button-official-rules')"
      target="_blank" to="/OfficialRules"
      >Official Rules</router-link
    >
    for full details on eligibility requirements (including qualifying
    Post-Secondary Schools), how to enter, winner selection procedure, prize
    description and limitations. Void where prohibited.
  </p>
</template>
<script>
export default {
  methods: {
    trackButtonClick(event) {
      this.$gtag.event(`${event.toLowerCase().trim().replaceAll(' ', '-')}`, {
        event_category: 'hinge-advocates',
        event_label: `${event.toLowerCase().trim().replaceAll(' ', '-')}`,
        value: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-rules-form {
  span {
    font-weight: bold;
    font-family: ModernEraBold;
    text-transform: uppercase;
  }
  p {
    font-family: ModernEra;
    font-size: 16px !important;
  }
}
</style>
