<template>
  <div class="footer-container">
    <div class="title-container">
      <img src="/img/footer_hinge_logo.gif" alt="" @click="handleLogoClick">
    </div>
    <div class="right-info-container">
      <div class="text-container">
      <p class="first">Hinge, the dating app designed to be deleted.</p>
      <p>Hinge is built on the belief that anyone looking for love should be able
        to find it. That's why we built an app that's designed to get people off
        their phones and out on dates. You can learn more about recent initiatives
        <a
        @click="trackButtonClick('footer-button-press')"
        href="https://www.hinge.co/press/" target="_blank">here</a>.</p>
      <p>This website is owned and operated by Probability, LLC as Contest Administrator
        for Hinge, Inc.</p>
      </div>
      <div class="rules-container">
        <div>
          <!-- <a @click="goRules" href="">Official Rules</a> -->
          <!-- <router-link @click.native="scrollTop" to="/OfficialRules" -->
          <router-link
            @click.native="trackButtonClick('footer-button-official-rules')" to="/OfficialRules"
            target="_blank">
            Official Rules</router-link>
          <router-link
            @click.native="trackButtonClick('footer-button-faqs')"
            to="/faq" target="_blank">
            FAQ</router-link>
          <!-- <a @click="goFaqs" href="">FAQ</a> -->
        </div>
        <div>
          <a
            @click="trackButtonClick('footer-button-terms')"
           target="_blank" href="https://www.hinge.co/terms/">Hinge Terms Of Use</a>
          <a
          @click="trackButtonClick('footer-button-hinge-privacy-policy')"
          target="_blank" href="https://www.hinge.co/privacy/">Hinge Privacy Policy</a>
        </div>
        <a @click="trackButtonClick('footer-button-probability-privacy-policy')"
        href="https://www.probabilityworks.com/privacypolicy" target="_blank">Probability Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteFooter',
  components: {
  },
  data() {
    return {

    };
  },
  methods: {
    trackButtonClick(event) {
      this.$gtag.event(`${event.toLowerCase().trim().replaceAll(' ', '-')}`, {
        event_category: 'hinge-advocates',
        event_label: `${event.toLowerCase().trim().replaceAll(' ', '-')}`,
        value: 0,
      });
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // setTimeout(() => {
      //   window.scrollTo({ top: 0, behavior: 'smooth' });
      // }, 50);
    },
    handleLogoClick() {
      this.$router.push({ path: '/' }, window.scrollTo({ top: 0 }));
    },
    goRules() {
      this.$router.push({ path: '/OfficialRules' });
    },
    goFaqs() {
      this.$router.push({ path: '/faq' });
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-container{
  background: $black-footer;
  color: white;
  display: grid;
  grid-template-columns: auto;
  padding: 0 18px;
  @include respond-to('small'){
    grid-template-columns: 54% 46%;
    justify-content: space-between;
    padding: 0 48px;
  }
  .title-container{
    img{
      width: 130px;
      margin: 50px 0 20px -10px;
      //padding: 0 18px 0 18px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
      @include respond-to('small'){
        width: 150px;
        margin: 65px 0 0 0;
        padding: 0 0 0 18px;
      }
    }
  }
  .text-container{
    .first{
      font-family: 'ModernEraBold';
      margin: 0 0 20px 0;
      //padding: 0 100px 0 20px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      @include respond-to('small'){
        margin: 80px 0 20px 0;
        line-height: 30px;
        //padding: 0 100px 0 20px;
      }
    }
    p{
      font-family: 'ModernEra';
      margin: 0 0 20px 0;
      font-size: 16px;
      //padding: 0 20px;
      text-align: left;
      line-height: 24px;
      color: white;
      a{
        color: white;
        font-size: 16px;
      }
      @include respond-to('small'){
        width: 98%;
        line-height: 30px;
      }
    }
  }
  .rules-container{
    margin: 40px 0;
    a{
      display: flex;
      flex-direction: column;
      font-family: 'ModernEra';
      font-size: 16px;
      margin: 0 0 20px 0;
      padding-right:20px;
      text-align: left;
      line-height: 24px;
      color: white;
    }
    @include respond-to('small'){
      display: grid;
      grid-template-columns: auto auto auto;
      margin: 80px 0 40px 0;
      line-height: 30px;
    }
  }
}
</style>
