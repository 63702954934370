<template>
  <div class="home">
    <hinge-mental-health-header></hinge-mental-health-header>
    <div class="confirmation-info">
      <div class="left-info">
        <h1>
          Thanks for your interest in Mental Health Advocates of Tomorrow!
          <br>
          <br>
          <span>While the submission period has ended,
          we're grateful for professionals (and future professionals) like you.</span>
        </h1>
        <!-- <p class="p">
          The Contest Entry Period has ended.
        </p> -->
        <!-- <p>We’ll be reviewing applications now through Friday, June 17, 2022. If you are
          named a finalist, you will hear from us by the week of Monday, July 18, 2022,
          and grant recipients will be announced around August 17, 2022.</p>
          <p>For more details on the application review process, check out our
            <router-link to="/faq" target="_blank">
            FAQ</router-link> page.</p> -->
      </div>
      <div class="right-info">
        <img src="/img/main_section_1_photos.png" alt="">
      </div>
    </div>
    <div class="summary-container">
      <summary-rules-form></summary-rules-form>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteFooter from '@/components/Global/SiteFooter.vue';
import HingeMentalHealthHeader from '@/components/Global/HingeMentalHealthHeader.vue';
import SummaryRulesForm from '@/components/Global/SummaryRulesForm.vue';

export default {
  name: 'ConfirmationPage',
  components: {
    SiteFooter,
    HingeMentalHealthHeader,
    SummaryRulesForm,
  },
  methods: {
    goFaqs() {
      this.$router.push({ path: '/faq' });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-info{
  display: grid;
  padding: 0 20px;
  margin: 0 0 72px auto;
  grid-template-columns: auto;
  @include respond-to('small'){
    padding: 0 40px;
    max-width: 1000px;
    margin: 42px auto 196px auto;
    grid-template-columns: 60% 40%;
  }
  .left-info{
    width: 100%;
    padding: 0 0 0 72px;
    @include respond-to('small'){
      width: 90%;
      padding: 0 0 0 0 ;
    }
    h1{
      font-weight: 500;
      //line-height: 40px;
      margin: 40px 0;
      @include respond-to('small'){

      }

      span {
        font-size: 35px;
        line-height: 1.3;
      }
    }
    p{
      margin: 0 0 20px 0;
      @include respond-to('small'){
        margin: 0 0 40px 0;
      }
    }
    .p{
      font-weight: bolder;
      margin: 0 0 40px 0;
      @include respond-to('small'){
        margin: 20px 0 40px 0;
      }
    }
    a{
      color: $black;
    }
  }
  .right-info{
    img{
      width: 100%;
      max-width: 350px;
      @include respond-to('small'){
        max-width: 380px;
      }
    }
  }
}
  .summary-container {
    font-size: 16px;
    line-height: 30px;
    width: 90%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 0px 48px 48px 0px;
    & > p {
      font-size: 16px;
      line-height: 30px;
    }
  }

</style>
