<template>
  <div class="coming-soon-page">
    <hinge-mental-health-header></hinge-mental-health-header>
    <div class="coming-soon">
      <h1 class="large-title">Coming Soon!</h1>
      <summary-rules></summary-rules>

    </div>
    <!--<site-footer></site-footer>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import SiteFooter from '@/components/Global/SiteFooter.vue';
import HingeMentalHealthHeader from '@/components/Global/HingeMentalHealthHeader.vue';
import SummaryRules from '../components/Global/SummaryRules.vue';

export default {
  name: 'ConfirmationPage',
  components: {
    // SiteFooter,
    HingeMentalHealthHeader,
    SummaryRules,
  },
  methods: {
    goFaqs() {
      this.$router.push({ path: '/faq' });
    },
  },
};
</script>

<style lang="scss" scoped>
.coming-soon-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;

  .coming-soon{
    padding: 0 20px;
    width:100%;
    max-width: 1000px;
    margin:0 auto;
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    @include respond-to('small') {
      padding: 15vh 20px;
    }
  }

  .large-title {
    margin-bottom: 10vh;
    font-size: 11vw;
    text-align: center;

    @include respond-to('small') {
      font-size: 65px !important;
      margin-bottom: 15vh;
    }
    @include respond-to('medium') {
      font-size: 80px !important;
    }
    @include respond-to('large') {
      font-size: 80px !important;
    }
  }
}

</style>
