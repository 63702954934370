<template>
  <p class="summary-rules">
    <span>No Purchase Necessary.</span> Enter between May 18, 2022 at 9:00 a.m.
    ET and June 17, 2022 at 11:59 p.m. ET. Open to legal residents of the 50
    U.S. & D.C., 18+, who are current students in the mental health field at a
    qualifying Post-Secondary School who demonstrate a commitment to supporting
    LGBTQIA+ and/or BIPOC communities. Finalists notified on/about June 18, 2022
    and must supplement entry by submitting video. Finalists must also agree to
    background check. See
    <router-link
      @click="trackButtonClick('legal-button-official-rules')"
      target="_blank" to="/OfficialRules"
      >Official Rules</router-link
    >
    for full details on eligibility requirements (including qualifying
    Post-Secondary Schools), how to enter, winner selection procedure, prize
    description and limitations. This promotion is not affiliated with Meta. Void where prohibited.
  </p>
</template>

<script>
export default {
  methods: {
    trackButtonClick(event) {
      debugger;
      this.$gtag.event(`${event.toLowerCase().trim().replaceAll(' ', '-')}`, {
        event_category: 'hinge-advocates',
        event_label: `${event.toLowerCase().trim().replaceAll(' ', '-')}`,
        value: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-rules {
  span {
    font-weight: bold;
    font-family: ModernEraBold;
    text-transform: uppercase;
  }
  p {
    font-family: ModernEra;
    font-size: 12px;
    @include respond-to("small") {
      font-size: 14px;
    }
  }
}
</style>
