import Vue from 'vue';
import rules from '@/validations/rules';
import { ValidationProvider } from 'vee-validate';
import VueMask from 'v-mask';
import VueScrollShow from 'vue-scroll-show';
import Toasted from 'vue-toasted';
import VueGtag from 'vue-gtag';
// import { DateTime } from 'luxon';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

rules();

Vue.config.productionTip = false;
Vue.use(Toasted, {
  duration: 1500,
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueMask);
Vue.use(VueScrollShow);
// Vue.use(DateTime);

// const clientGA = process.env.VUE_APP_CLIENT_GA;
// const gaTrackingCodes = [{ id: process.env.VUE_APP_GA }];
// if (clientGA != null && clientGA.length > 2) {
//   gaTrackingCodes.push({ id: clientGA });
// }

// if (process.env.VUE_APP_GA) {
//   app.use(VueGtag, {
//     // config: { id: process.env.VUE_APP_GA },
//     includes: gaTrackingCodes,
//     params: {
//       send_page_view: true,
//     },
//   });
// }

const gaTrackingCodes = [
  { id: process.env.VUE_APP_GA },
];

// const clientGA = process.env.VUE_APP_CLIENT_GA;
// if (clientGA != null && clientGA.length > 2) {
//   gaTrackingCodes.push({ id: clientGA });
// }

if (process.env.VUE_APP_GA) {
  Vue.use(VueGtag, {
    // config: { id: process.env.VUE_APP_GA },
    includes: gaTrackingCodes,
    params: {
      send_page_view: true,
    },
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

console.log(`Environment::${process.env.VUE_APP_ENV}`);
console.log(`BASE_URL::${process.env.VUE_APP_BASE_API}`);
