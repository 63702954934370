import {
  extend,
  setInteractionMode,
} from 'vee-validate';

import {
  required,
  email,
  numeric,
  confirmed,
} from 'vee-validate/dist/rules';
// import API from '../lib/api';
import API from '@/lib/Api';

const moment = require('moment-timezone');

setInteractionMode('eager');
// setInteractionMode('aggressive');

export default function () {
  extend('email', {
    ...email,
    message: 'This email is invalid',
  });
  extend('length', {
    validate(value, args) {
      return value.length >= args.length ? true : `This field should be ${args.length} characters long.`;
    },
    params: ['length'],
  });
  extend('required', {
    ...required,
    message: 'This field is required',
  });
  extend('confirmed', {
    ...confirmed,
    message: 'This field doesn\'t match',
  });
  extend('numeric', {
    ...numeric,
    message: 'This field must be a number',
  });
  extend('min', {
    validate(value, args) {
      return value.length >= +args.length ? true : `This field should be at least ${args.length} characters long.`;
    },
    params: ['length'],
  });
  extend('validtime', {
    validate(value) {
      // \d{1,2}:\d{1,2}:\d{1,2} [AP]M\z
      const isValid = value.match(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/gm);
      return isValid ? true : 'Invalid time.';
    },
    params: ['length'],
  });
  extend('max_words', {
    validate(value, args) {
      // return value.length < args.length ? true : args.length+' words max.';
      if (typeof (value) !== 'string') {
        return true;
      }
      const wordCount = value.match(/(\w+)/g).length ?? 0;
      const validationPassed = wordCount <= +args.length;
      return (validationPassed) || `${+args.length} words max`;
    },
    params: ['length'],
  });
  extend('phone', {
    validate(value) {
      // \d{1,2}:\d{1,2}:\d{1,2} [AP]M\z
      const isValid = value.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/gm);
      return isValid ? true : 'Invalid phone number.';
    },
    params: ['length'],
  });
  extend('phone2', {
    validate(value) {
      // \d{1,2}:\d{1,2}:\d{1,2} [AP]M\z
      const isValid = value.match(/^\d{3}-\d{3}-\d{4}$/gm);
      return isValid ? true : 'Invalid phone number.';
    },
    params: ['length'],
  });
  extend('requiredcheckbox', {
    validate(value) {
      const isValid = value === true;
      return isValid ? true : 'This field is required';
    },
    params: ['length'],
  });
  // extend('singleEntry', {
  //   async validate(value) {
  //     const data = await API.isValidEmail(value);
  //     // console.log(data);
  //     // debugger;
  //     return data.valid ? true : data.reason;
  //   },
  // });
  extend('singleEntry', {
    async validate(value) {
      const data = await API.checkEntry({ email: value });
      // console.log(data);
      // debugger;
      if (data.success !== true) {
        return 'An error has ocurred';
      }
      return data.data.allowed ? true : 'This email already submitted';
    },
  });

  extend('validzip', {
    validate(value) {
      const isUSZip = value.match(/^\d{5}$|^\d{5}-\d{4}$/);
      const isCAZip = value.match(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
      const isValid = isUSZip || isCAZip;
      return isValid ? true : 'Invalid Postal Code/Zip Code';
    },
  });
  extend('18+', {
    validate(value) {
      console.log(value);
      if (value === 'Invalid date') {
        return true;
      }
      const years = moment().diff(value, 'years', false);
      const isValid = years >= 18;
      return isValid ? true : 'You must be 18+ to enter';
    },
  });
}
