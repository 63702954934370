import axios from 'axios';

const Api = {
  async checkEntry({ email }) {
    try {
      const url = `${process.env.VUE_APP_BASE_API}/check-entry/${email}`;
      const config = {};

      const result = await axios.get(url, config);

      const { data } = result;
      // console.log(result, data);
      // debugger;

      return {
        success: true,
        message: 'Ok',
        data,
      };
    } catch (e) {
      const response = {
        success: false,
        message: e.message,
      };

      return response;
    }
  },
  async submitForm(payload) {
    try {
      const url = `${process.env.VUE_APP_BASE_API}/entry/`;
      // const url = 'https://api.hingeadvocates.probabilityworks.cloud/entry/';
      const config = {};

      const result = await axios.post(url, payload, config);

      const { data } = result;
      // console.log(result, data);
      // debugger;

      return {
        success: true,
        message: 'Ok',
        data,
      };
    } catch (e) {
      const response = {
        success: false,
        message: e.message,
      };

      return response;
    }
  },
  async submitFormTool(payload) {
    try {
      // const url = 'https://api.hingeadvocates.probabilityworks.cloud/entry/';
      const environment = (process.env.VUE_APP_ENV === 'production') ? 'prod' : 'test';
      const url = `https://probabilty-tool-dev.herokuapp.com/submission/${environment}/62991bda631a085cbaf6eca1`;
      const config = {
        headers: {
          'x-token-auth': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MjFkMDE4Y2NjMTQ1ZWEwMjM1MmNkNTQiLCJlbWFpbCI6ImNhbWlsby5ib3JyZXJvQGhlbGxvZGVicmFpbi5jb20ifQ.UuXzQ05Ak2XnkUOfzEYpLWXUFK91kgiPD1d_nX5g50M',
        },
      };
      const cleanPayload = { ...payload };
      cleanPayload.source = environment;

      const result = await axios.post(url, cleanPayload, config);
      const { data } = result;
      // console.log(result, data);
      // debugger;

      return {
        success: true,
        message: 'Ok',
        data,
      };
    } catch (e) {
      const response = {
        success: false,
        message: e.message,
      };

      return response;
    }
  },
};

export default Api;
