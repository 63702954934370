import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    observer: null,
  },
  mutations: {
    initIntObserver(state) {
      const options = {
        rootMargin: '-50px',
        threshold: 0.01,
      };

      const onObserve = (entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }
          const id = entry.target.getAttribute('data-ref') ?? null;
          // console.log(id, entry.target);
          const delay = +entry.target?.getAttribute('data-delay') ?? 0;
          const classesString = entry.target?.getAttribute('data-classes') ?? '';

          setTimeout(() => {
            const classes = classesString.split(' ');
            classes.forEach((cl) => {
              entry.target.classList.add(cl);
              const animate = cl === 'animate';
              if (animate && id) {
                this[id] = true;
              }
              if (cl === 'fade-in-bottom') {
                entry.target.classList.remove('opacity-0');
              }
            });
          }, delay);
          observer.unobserve(entry.target);
        });
      };

      const observer = new IntersectionObserver(onObserve, options);
      state.observer = observer;
    },
    destroyObserver(state) {
      state.observer.disconnect();
      state.observer = null;
    },
  },
  actions: {
  },
  modules: {
  },
});
