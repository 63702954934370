<template>
  <div class="faq">
    <hinge-mental-health-header></hinge-mental-health-header>
    <div class="intro">
      <h1 class="title">
      FAQ</h1>
      <p class="subtitle">
        Curious to learn more about our Mental Health Advocates of Tomorrow Initiative?
        Check out some answers to some of our most frequently asked questions below!
      </p>
    </div>
    <div class="questions">
      <!-- <h1>FAQ</h1> -->
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <p class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              What is Hinge?
            </button>
          </p>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Hinge is the dating app that is designed to be deleted™.
                <br><br>
                We believe anyone looking for love should be able to find it,
                so  we created an app that's designed to get people off their
                phones and out on great dates.
                <br><br>
                We're committed to support Hinge daters and broader communities.
                You can learn more about our recent initiatives and their impact
                at <a href="https://hinge.co/press" target="_blank">hinge.co/press</a>
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              What is the goal of this initiative?
            </button>
          </p>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                The goal of the Mental Health Advocates of Tomorrow Initiative
                is to expand therapy access for BIPOC and LGBTQIA+ daters.
                <br><br>
                Empathetic therapists help us build healthy relationships,
                but statistics reveal a serious lack of licensed therapists
                from within, or specifically for, marginalized people.
                Through Mental Health Advocates of Tomorrow, we're financially
                supporting future providers and educators with a passion for
                helping  LGBTQIA+ and/or BIPOC communities reach their goals.

              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Who is eligible for this grant?
            </button>
          </p>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                To be eligible, an applicant must be:
                <br>
                <br>
              <!-- </p> -->
              <ul>
                <li>
                  A current student in the mental health field
                  (including counseling, therapy, psychology/psychiatry,
                  or social work) at a Post-Secondary School.
                </li>
                <li>
                  Able to demonstrate a commitment to supporting mental
                  health for BIPOC and/or LGBTQIA+ individuals.
                </li>
                <li>
                  A United States resident who is at least 18 years of age, or older.
                </li>
              </ul>
              <br>
              <!-- <p> -->
                See Section 3 of the
                <router-link to="/OfficialRules/#eligibility" target="_blank">
                Official Rules</router-link>
                for full details on eligibility requirements.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-4">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-4"
              aria-expanded="false"
              aria-controls="collapse-4"
            >
              How many people will receive a grant?
            </button>
          </p>
          <div
            id="collapse-4"
            class="accordion-collapse collapse"
            aria-labelledby="heading-4"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Hinge will award 10 grant recipients $10,000
                each at the end of the application process.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-5">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-5"
              aria-expanded="false"
              aria-controls="collapse-5"
            >
              How long do I have to submit my application?
            </button>
          </p>
          <div
            id="collapse-5"
            class="accordion-collapse collapse"
            aria-labelledby="heading-5"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                The open application window closes at 11:59 p.m.
                eastern time on Friday, June 17.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-6">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-6"
              aria-expanded="false"
              aria-controls="collapse-6"
            >
              How will my essay be judged?
            </button>
          </p>
          <div
            id="collapse-6"
            class="accordion-collapse collapse"
            aria-labelledby="heading-6"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                See Section 8 of the
                <router-link to="/OfficialRules/#essay" target="_blank">
                Official Rules</router-link>
                for more info on the judging criteria.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-7">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-7"
              aria-expanded="false"
              aria-controls="collapse-7"
            >
              What are the content guidelines for submitting an essay?
            </button>
          </p>
          <div
            id="collapse-7"
            class="accordion-collapse collapse"
            aria-labelledby="heading-7"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Some key things to remember when submitting your essays:
                <br><br>
                <ul>
                  <li>
                    Your submission must be truthful and accurate
                  </li>
                  <li>
                    Your submission must not contain any personally identifiable
                    information for individuals other than yourself
                  </li>
                  <li>
                    Your submission must not contain any confidential
                    information or any financial information.
                    Financial information/status will not be considered in your essay submissions
                  </li>
                  <li>
                    Your submission should not contain brand names or trademarks that violates
                    or infringes any third party rights of any kind, (except identification of the
                    Post Secondary School where Entrant is taking
                    courses in the mental health field)
                  </li>
                  <li>
                    Your submission must be original and not contain any content you did not create
                  </li>
                  <li>
                    Your submission must not contain material that is misleading,
                    inappropriate, indecent, etc.
                  </li>
                  <li>
                    Your submission must not contain content that reflects, advocates,
                    or promotes bigotry,
                    racism, hatred, exploitation, etc.
                  </li>
                </ul>
                <br>
                See Section 7 of the
                <router-link to="/OfficialRules/#contentGuidelines" target="_blank">
                Official Rules</router-link>
                for full Content Guidelines.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-8">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-8"
              aria-expanded="false"
              aria-controls="collapse-8"
            >
              What happens if my Essay is chosen?
            </button>
          </p>
          <div
            id="collapse-8"
            class="accordion-collapse collapse"
            aria-labelledby="heading-8"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                After reviewing all submissions received during the application window,
                Hinge will select a list of up to 20 finalists. These finalists will be
                notified individually via phone and/or email and will receive instructions
                for how to proceed onto the next and final round. Each finalist will submit
                a final application that includes a video response (up to 3-minutes in length)
                to a specific prompt.
                <br>
                <br>
                Ten winners will ultimately be selected from the group of
                finalists to receive a grant.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <p class="accordion-header" id="heading-9">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-9"
              aria-expanded="false"
              aria-controls="collapse-9"
            >
              How will I know if my essay is chosen?
            </button>
          </p>
          <div
            id="collapse-9"
            class="accordion-collapse collapse"
            aria-labelledby="heading-9"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Finalists will be notified directly on or around
                Monday, July 18 via phone and/or email based on the
                information provided in the application.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item" id="accordionItem-10">
          <p class="accordion-header" id="heading-10">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-10"
              aria-expanded="false"
              aria-controls="collapse-10"
            >
              Will I hear from you if my essay is not chosen?
            </button>
          </p>
          <div
            id="collapse-10"
            class="accordion-collapse collapse"
            aria-labelledby="heading-10"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Only finalists will be notified that their essay has been chosen to advance.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item" id="accordionItem-11">
          <p class="accordion-header" id="heading-11">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-11"
              aria-expanded="false"
              aria-controls="collapse-11"
            >
              When will final winners be notified?
            </button>
          </p>
          <div
            id="collapse-11"
            class="accordion-collapse collapse"
            aria-labelledby="heading-11"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Selected finalists will be notified if they've been
                selected as a Grand Prize winner on or around Wednesday,
                August 17, 2022 directly via phone and/or email based on
                the information provided in the application.
                <br>
                <br>
                Finalists who aren’t selected to receive a grant will not be notified.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteFooter from '@/components/Global/SiteFooter.vue';
import HingeMentalHealthHeader from '@/components/Global/HingeMentalHealthHeader.vue';

export default {
  name: 'FrecuentlyQuestions',
  components: {
    SiteFooter,
    HingeMentalHealthHeader,
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      // setTimeout(() => {
      //   window.scrollTo({ top: 0, behavior: 'smooth' });
      // }, 50);
    },
    goRules() {
      this.$router.push({ path: '/OfficialRules' });
    },
    goFaqs() {
      this.$router.push({ path: '/faq' });
    },
  },
};
</script>

<style lang="scss" scoped>

.faq {
  .intro {
    max-width: 1000px;
    width:100%;
    margin: 0 auto;
    padding: 0 18px;
  @include respond-to('small'){
    padding: 0 48px;
  }
    h1 {
      line-height: 1.1;
      text-align: left;
      margin: 30px 0 0 0;
      @include respond-to('small'){
        margin: 70px 0 0 0;
      }
    }
    p {
      text-align: left;
      margin: 0 0 40px 0;
      @include respond-to('small'){
        margin: 0 0 32px 0;
      }
    }
    .subtitle{
      font-weight: bold;
    }
  }
.questions{
  padding: 0 18px;
  margin: 0 auto 72px auto;
  @include respond-to('small'){
    padding: 0 48px;
    max-width: 1000px;
    margin: 0 auto 196px auto;
  }
  .accordion-button:not(.collapsed) {
    color: $black;
    background-color: white;
    box-shadow: none;
  }
  .accordion-button:focus {
    border-color: none;
    box-shadow: none;
  }
  .accordion-button::after{
    color: $black;
  }
  .accordion-button:not(.collapsed)::after{
    background-image: url(/img/minus_faqs_icon.png);
  }
  .accordion-button::after{
    background-image: url(/img/plus_faqs_icon.png);
    img{
      width: 10px;
      height: 10px;
    }
  }
  .accordion-header{
    border-top: 1px solid $black;
  }
  .accordion-button{
    font-weight: bold;
    padding: 20px 0.2rem 20px 0;
    font-size: 20px;
  }
  .accordion-body{
    p{
      padding: 0 0 20px 0;
      width: 95%;
      @include respond-to('small'){
        width: 88%;
      }
    }

    ul {
      list-style: disc;
      padding-left: 5px;
      li {
        margin-left: 35px;
        margin-bottom: 15px;
      }
    }
  }
  #accordionItem-11{
    border-bottom: 1px solid $black;
    border-radius: 0;
  }
}
}
</style>
