<template>
  <div class="home">
    <div class="main-content">
      <section-one></section-one>
      <section-two></section-two>
      <section-three></section-three>
      <application-form v-if="showForm"></application-form>
      <div class="notice-closing" v-else>
        <h1>
          Thank you for your Interest.
          <br>
          The Contest Entry Period has ended.
        </h1>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteFooter from '@/components/Global/SiteFooter.vue';
import SectionOne from '@/components/Home/SectionOne.vue';
import SectionThree from '@/components/Home/SectionThree.vue';
import ApplicationForm from '@/components/Home/ApplicationForm.vue';
import SectionTwo from '@/components/Home/SectionTwo.vue';
import { DateTime } from 'luxon';

export default {
  name: 'Home',
  components: {
    SiteFooter,
    SectionOne,
    SectionTwo,
    SectionThree,
    ApplicationForm,
  },
  data() {
    return {
      showForm: true,
      timer: null,
    };
  },
  methods: {
    checkOpen() {
      const closeTime = DateTime.fromSQL('2022-06-17 23:59:59', { zone: 'America/New_York' });
      // const closeTime = DateTime.fromSQL('2022-06-10 14:32:00', { zone: 'America/New_York' });
      const now = DateTime.now();
      const isOpen = now <= closeTime;
      return isOpen;
    },
    handleCampaignClosed() {
      this.showForm = false;
      this.stopTimer();
      this.$router.push({ path: '/CampaignClosed' });
    },
    initTimer() {
      if (this.timer == null) {
        this.stopTimer();
      }
      this.timer = setInterval(
        () => {
          const isOpen = this.checkOpen();
          if (!isOpen) {
            this.handleCampaignClosed();
            // this.showForm = false;
            // this.stopTimer();
          }
          this.showForm = isOpen;
        },
        5000,
      );
    },
    stopTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  beforeMount() {
    const isOpen = this.checkOpen();
    if (!isOpen) {
      // this.showForm = false;
      this.handleCampaignClosed();
    } else {
      this.initTimer();
    }
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  @include respond-to('small'){
    margin-top: 100px;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    flex:1;
    width: 100%;
    max-width: 1000px;
    margin:0 auto;
  }

  .notice-closing {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5rem 0;
    margin-bottom: 5rem;
  }
}
</style>
