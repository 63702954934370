<template>
  <div class="official-rules">
    <hinge-mental-health-header></hinge-mental-health-header>
    <div class="rules">
      <h1>Rules</h1>
      <p dir="ltr">
        <b >Hinge Inc.</b>
      </p>
      <p dir="ltr">
        <b >Mental Health Advocates of Tomorrow</b>
      </p>
      <p dir="ltr" class="text">
        <b >Official Rules</b>
      </p>
      <p dir="ltr">
        <b>NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN.</b>
      </p>
      <br />
      <p dir="ltr">
        <b>
          OPEN ONLY TO LEGAL RESIDENTS OF THE FIFTY (50) UNITED STATES AND THE
          DISTRICT OF COLUMBIA WHO, AS OF THE TIME OF ENTRY, ARE AT LEAST
          EIGHTEEN (18) YEARS OF AGE (OR AGE OF MAJORITY IN THEIR JURISDICTION
          OF RESIDENCE, WHICHEVER IS OLDER), AND ARE ENROLLED AS A STUDENT IN A
          MINIMUM OF ONE COURSE AT AN ACCREDITED POST SECONDARY SCHOOL OR
          PROGRAM IN THE MENTAL HEALTH FIELD (INCLUDING COUNSELING, THERAPY,
          PSYCHOLOGY/PSYCHIATRY, OR SOCIAL WORK) WHO DEMONSTRATE A COMMITMENT TO
          SUPPORTING LGBTQIA+ AND/OR BIPOC COMMUNITIES. FOR PURPOSES OF THIS
          CONTEST, A “POST SECONDARY SCHOOL” IS AN INSTITUTION OF HIGHER
          EDUCATION THAT AWARDS DEGREES, CERTIFICATES AND/OR PROFESSIONAL
          CERTIFICATIONS AND INCLUDES, BUT IS NOT LIMITED TO,
          COLLEGES/UNIVERSITIES, VOCATIONAL SCHOOLS, TRADE SCHOOLS AND
          ACADEMIES. SPONSOR'S/ADMINISTRATOR'S DETERMINATIONS REGARDING WHETHER
          A PARTICULAR SCHOOL OR PROGRAM IS A POST SECONDARY SCHOOL AND/OR
          WHETHER AN ENTRANT MEETS THE SCHOLASTIC CRITERIA IS OTHERWISE ELIGIBLE
          SHALL BE FINAL AND BINDING AND NOT SUBJECT TO CHALLENGE OR APPEAL.
          VOID WHERE PROHIBITED OR RESTRICTED BY LAW.
        </b>
      </p>
      <br />
      <p dir="ltr">
        <b>CONTEST OVERVIEW:</b> The Hinge Mental Health Advocates of Tomorrow
        Initiative (<b>“Initiative”</b> or <b>“Contest”</b>) invites future
        therapists who intend to support BIPOC and LGBTQIA+ communities to apply
        and respond to two (2) Essay (defined below) prompts. The Entries (as
        defined below) will be reviewed and scored by a combined panel of judges
        chosen by Administrator and Sponsor to select up to twenty* (20)
        finalists (<b>“Finalists”</b>). The Finalists' Entries and Finalist
        Videos (defined below) will then be reviewed and scored by another
        judging panel chosen by Sponsor to select ten (10)* Grand Prize winners
        (<b>“Winners”</b>) who will each receive $10,000 to help fund their
        education.
        <br />
        <br />
      </p>
      <p dir="ltr">
        *In one or more rounds of judging, Sponsor reserves the right to select
        fewer than the indicated number of Entries for advancement to the next
        round of judging if in the sole discretion of the corresponding judging
        panel there is not a sufficient number of eligible entries of sufficient
        quality in such round of judging.
      </p>
      <br />
      <p dir="ltr">
        <b>1. Sponsor:</b>
      </p>
      <p dir="ltr">
        Hinge, Inc., 809 Washington Street, Third Floor, New York, NY 10014.
      </p>
      <br />
      <p dir="ltr">
        <b>2. Administrator:</b>
      </p>
      <p dir="ltr">
        Probability LLC, 2 Woodstock Meadows Ln, PO Box 1484, Woodstock, NY
        12498.
      </p>
      <br />
      <p dir="ltr" id="eligibility">
        <b>3. Eligibility:</b>
      </p>
      <p dir="ltr">
        The Hinge Mental Health Advocates of Tomorrow Initiative is open only to
        individuals who are (i) at least eighteen (18) years of age (or the age
        of majority in their jurisdiction of residence, whichever is older) at
        the time of entry, (ii) a legal resident of the fifty (50) United States
        or the District of Columbia, and (iii) a current student in the mental
        health field (including counseling, therapy, psychology/psychiatry, or
        social work) at a Post-Secondary School (as defined above) and (iv)
        demonstrate a commitment to supporting mental health for BIPOC and/or
        LGBTQIA+ communities. Void where prohibited or restricted by law. The
        following individuals are not eligible to enter: employees, contractors,
        representatives, agents, directors, and officers of Hinge, Inc.
        (<b>"Sponsor"</b>), Probability LLC (<b>"Administrator"</b>), CSM Sport
        and Entertainment Inc (<b>“CSM”</b>), each of their parent, subsidiary
        and affiliated companies, marketing agencies, or any other parties
        involved in the administration of the Contest (collectively, the
        <b>"Promotion Entities"</b>) and each of the immediate family members of
        such excluded individuals (i.e., spouses, parents, children, siblings
        and the "steps" of each) and all other persons living in the same
        household of such excluded individuals. By participating in this
        Contest, Entrants (defined below) agree to be bound by these official
        rules (<b>"Official Rules"</b>) and by the decisions of the Sponsor,
        Administrator and the judges selected by Sponsor in all matters relating
        to the Contest.
      </p>
      <br />
      <p dir="ltr">
        By entering, each entrant (<b>“Entrant”</b>) understands and agrees
        that, if selected as a potential Finalist, they will be required to
        consent to a background check as part of the verification procedure;
        and, failure to sign such consents and authorizations and/or to furnish
        all required information will result in disqualification. The background
        check will include review of Entrant's education information, social
        media accounts, and activity on dating apps owned or affiliated with
        Match Group LLC. Sponsor reserves the right to disqualify a Winner
        subject to the results of the background check. Disqualification may
        occur, in Sponsor's sole discretion, if Entrant has committed, been
        convicted of, or pled no contest to a felony or indictable offense (or
        crime of similar severity), a sex crime, or any crime involving violence
        or a threat of violence or any crime of moral turpitude.
      </p>
      <br />
      <p dir="ltr">
        <b>4. Entry Period:</b>
      </p>
      <p dir="ltr">
        The entry period begins on May 18, 2022 at 9:00 am Eastern Time
        (<b>"ET"</b>) and ends on June 17, 2022 at 11:59 pm ET (<b
          >"Entry Period"</b
        >). Administrator's computer is the official time keeping device for the
        Contest.
      </p>
      <br />
      <p dir="ltr" class="text-center mb-2">
        <b>Overview of Key Dates and Times:</b>
      </p>
      <div dir="ltr" align="left" class="d-flex justify-center text-center">
        <table class="dates">
          <colgroup>
            <col width="191" />
            <col width="163" />
            <col width="162" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <p dir="ltr">Period</p>
                <br />
              </th>
              <th>
                <p dir="ltr">Begin Date &amp; Time</p>
                <br />
              </th>
              <th>
                <p dir="ltr">End Date &amp; Time</p>
                <br />
              </th>
            </tr>
            <tr>
              <td>
                <p dir="ltr">Entry Period</p>
                <br />
              </td>
              <td>
                <p dir="ltr">9:00 am ET on 5/18/22</p>
                <br />
              </td>
              <td>
                <p dir="ltr">11:59 pm ET on 6/17/22</p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">Contest Judging</p>
              </td>
              <td>
                <p dir="ltr">5/18/22</p>
                <br />
              </td>
              <td>
                <p dir="ltr">7/14/22</p>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">Finalist Video Submission</p>
              </td>
              <td>
                <p dir="ltr">7/19/22</p>
              </td>
              <td>
                <p dir="ltr">8/2/22</p>
              </td>
            </tr>
            <tr>
              <td>
                <p dir="ltr">Finalist Judging</p>
              </td>
              <td>
                <p dir="ltr">8/2/22</p>
              </td>
              <td>
                <p dir="ltr">8/16/22</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <p dir="ltr">
        <b>5. How to Enter:</b>
      </p>
      <p dir="ltr">
        During the Entry Period, navigate the Internet to
        HingeAdvocatesofTomorrow.com (the “Website”). On the Website, you must
        complete the entry form (“Entry Form” or “Application”) by submitting
        your personal information and your responses to two (2) essay questions
        as defined below. The Entry Form will request your:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">Full name</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Pronouns (optional)</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Email address</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Daytime phone number</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Current city &amp; state of residence</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Name (and address) of school/program of attendance</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Type of degree</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Enrollment status</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Major or field of study</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Year of Enrollment/ Expected Graduation Date</p>
        </li>
        <!-- <ul> -->
        <li dir="ltr">
          <p dir="ltr">
            Relevant experience and/or knowledge surrounding the mental health
            and wellness of LGBTQIA+ and BIPOC communities in the following
            categories (1-2 sentences each).
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">Professional experience</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Volunteer experience</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Courses or school experience</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Learning experiences</p>
          </li>
        </ul>
        <!-- </ul> -->
      </ul>
      <br />
      <p dir="ltr">
        After you provide your personal information, you must answer both of the
        following two (2) prompts (each an <b>“Essay”</b>, collectively
        <b>“Essays”</b>)
      </p>
      <br />
      <p dir="ltr" class="padding-left">
        <b>Prompt #1:</b> Please share your insights/perspectives on the mental
        health and wellness needs of
      </p>
      <p dir="ltr" class="padding-left">
        LGBTQIA+ and/or BIPOC communities (up to 200 words).
      </p>
      <br />
      <p dir="ltr" class="padding-left">
        <b>Prompt #2:</b> How do you plan to invest in stronger mental health
        support for BIPOC &amp; LGBTQIA+ folks throughout your career? (up to 400
        words).
      </p>
      <br />
      <p dir="ltr">
        To submit Essays, you must type each Essay completely in the
        corresponding text box. Essays <b class="underline">must</b> be
        submitted on-line as part of the Entry Form (e.g., e-mail attachments
        will not be accepted). Entry must be created for the sole purpose of
        this Contest. <b>ESSAYS MUST BE SOLELY YOUR ORIGINAL WORK.</b> DO NOT
        COPY OTHERS' WORKS, IN WHOLE OR IN PART. YOU MUST OTHERWISE COMPLY WITH
        THE CONTENT GUIDELINES BELOW AND OTHER TERMS HEREIN.
      </p>
      <p dir="ltr">
        <br />
        Personal information and Essays must all be completed in the same
        Internet session.
      </p>
      <p dir="ltr">
        <br />
        CLICK THE CHECK BOXES SIGNIFYING THAT YOU MEET THE ELIGIBILITY
        REQUIREMENTS AND HAVE READ AND AGREE TO THESE OFFICIAL RULES, SPONSOR'S
        TERMS OF USE (AVAILABLE AT
        <a href="https://hinge.co/TERMS/">HTTPS://HINGE.CO/TERMS/</a> AND
        PRIVACY POLICY (AVAILABLE AT
        <a href="https://www.hinge.co/privacy">HTTPS://WWW.HINGE.CO/PRIVACY/</a
        >). IF YOU DO NOT CHECK THE BOX INDICATING YOUR CONFIRMATION OF AND
        AGREEMENT TO THE ABOVE, YOU WILL NOT BE ENTERED IN THE CONTEST AND
        CANNOT WIN A PRIZE.
      </p>
      <br />
      <p dir="ltr">
        An <b>“Entry”</b> or <b>“Application”</b> consists of an individual's
        personal information as well as their two (2) Essays.
        <b> All Entries must be received by 11:59 pm ET on 6/17/22 </b>.
      </p>
      <br />
      <p dir="ltr">
        Incomplete submissions will not be accepted. To submit your Entry, click
        the "Enter" button on the Entry Form. Proof of entering information at
        the Website is not considered proof of delivery to or receipt by Sponsor
        of such Entry. Thank You Page merely indicates that your Entry has been
        received but does not constitute any representation that such Entry is
        eligible for the Contest.
      </p>
      <br />
      <p dir="ltr">
        <b>6. License Rights:</b>
      </p>
      <p dir="ltr">
        Once submitted, Essays cannot be modified by Entrant. Without limiting
        Sponsor's Terms of Use, by submitting the Essays (as well as Finalist
        Videos if Entrant is a Finalist, as discussed below), Entrant gives
        permission (and warrants and represents that they have obtained all
        rights necessary to give such permission and grant the license herein
        described, including from other persons depicted/referenced in the
        Essays and Finalist Video (if applicable) to Sponsor, and its designees.
        Entrant hereby grants Sponsor and its designees a royalty-free,
        irrevocable, perpetual, worldwide, sub-licensable, non-exclusive license
        (<b>“License”</b>) to broadcast, publish, store, reproduce, distribute,
        syndicate, and otherwise use and exhibit Entrant's Essays and Finalist
        Video (if applicable), name, likeness and all other information about
        the Entrant contained in the Essays and Finalist Video (if applicable)
        on any media and through any means now known or hereafter devised,
        including without limitation, the World Wide Web, and to otherwise use
        Entrant's Essays and Finalist Video (if applicable), name, likeness and
        all other information about Entrant in the Essays and Finalist Video (if
        applicable), and make derivative works therefrom, without compensation
        in accordance with the requirements of this Contest for purposes of
        trade or advertising without further compensation.es, and otherwise, as
        stated in these Official Rules (as solely determined by Sponsor and its
        designees). Entrants represent and warrant that they have full legal
        right, power and authority to grant Sponsor the foregoing license and if
        applicable, have secured all necessary rights from any participants in,
        and contributors to, their Essay or Finalist Video to grant such a
        license. Sponsor and Administrator are under no obligation to use any
        Essay or Finalist Video or return them to Entrants Sponsor's failure to
        receive all documentation from all applicable individuals within the
        prescribed time frame, in each instance as required by Sponsor in its
        sole discretion, will result in the disqualification of the entry.
      </p>
      <br />
      <p dir="ltr">
        <b>
          Limit one (1) Entry per person during the Entry Period, regardless of
          the number of e-mail addresses or accounts a person may have.
        </b>
        Multiple Entries in excess of this limitation or mass entry attempts may
        result in disqualification of all Entries by such person. Entries must
        be manually key-stroked by the Entrant; use of automated entry devices
        or programs, or entries by third parties, are prohibited. Incomplete
        Entries will be disqualified. Personal information collected in
        connection with this Contest will be treated in accordance with Hinge's
        Privacy Policy, available at
        <a href="https://www.hinge.co/privacy/">https://www.hinge.co/privacy/</a
        >.
      </p>
      <br />
      <p dir="ltr">
        If for any reason the Contest is not capable of running as planned,
        including infection by computer virus, bugs, tampering, unauthorized
        intervention, fraud or technical failures beyond Sponsor's control that
        corrupt or affect the administration, security, fairness, integrity or
        proper conduct of the Contest, Sponsor reserves the right, in its sole
        discretion, to disqualify any individual who tampers with the entry
        process, and to terminate, modify or suspend the Contest. In such event,
        Sponsor reserves the right to conduct the Contest and award the Grand
        Prize(s) in a manner which is fair, appropriate and consistent with
        these Official Rules, or to terminate the Contest and not award the
        Grand Prize(s), as determined by Sponsor in its sole discretion. Notice
        of termination/modification/suspension of Contest will be posted on the
        Website. Without limitation of any other term here, Sponsor and its
        agencies are not responsible for failed, partial or garbled computer
        transmissions, technical failures of any kind (including but not limited
        to electronic malfunction or damage of any network, hardware or
        software), any error, omission, interruption, deletion, defect, delay in
        operation or transmission, communications line failure, theft or loss,
        destruction or unauthorized access to, or alteration of, Entries or
        Finalist Videos (if applicable); or any problems or technical
        malfunctions of any telephone network or lines, computer online systems,
        servers, or providers, computer equipment, software, failure of any
        e-mail or electronic entry or Finalist Video (if applicable) to be
        received on account of technical problems or traffic congestion on the
        Internet or at any website, or any combination thereof, including any
        injury or damage to Entrant's or any other person's computer/mobile
        device** related to or resulting from participation in this Contest.
      </p>
      <br />
      <p dir="ltr">
        **Should Entrant participate in the Contest by using a mobile device,
        they will be responsible for wireless Internet access charges and any
        other fees imposed by their wireless carrier.
      </p>
      <br />
      <p dir="ltr">
        Entrants further agree not to knowingly damage or cause interruption of
        the Contest and/or prevent others from participating in the Contest.
        CAUTION: ANY ATTEMPT TO DAMAGE ANY ONLINE SERVICE OR WEBSITE OR
        UNDERMINE THE LEGITIMATE OPERATION OF THE CONTEST VIOLATES CRIMINAL OR
        CIVIL LAWS. IF SUCH AN ATTEMPT IS MADE, SPONSOR MAY DISQUALIFY ANY
        ENTRANT MAKING SUCH ATTEMPT AND SEEK DAMAGES TO THE FULLEST EXTENT OF
        THE LAW.
      </p>
      <br />
      <p dir="ltr" id="contentGuidelines">
        <b>7. Content Guidelines:</b>
      </p>
      <p dir="ltr">
        In addition to complying with all other requirements of these Official
        Rules; each Entry/Essay and Finalist Video (if applicable) must comply
        with the following guidelines (“Content Guidelines”). Any Essay or
        <b>
          Finalist Video (if applicable) that Sponsor/Administrator, in their
          sole discretion, determines is in violation of any Content Guideline
          may be disqualified from the Contest.
        </b>
        Each Entry/Essay and Finalist Video:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain personally identifiable information of individuals
            other than the Entrant;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Must not contain any financial information;</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Must not contain any confidential information;</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must be truthful and accurate (do NOT misrepresent your experience,
            enrollment in a Post Secondary School, educational achievements or
            exaggerate your accomplishments);
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain brand names, logos, or trademarks other than those
            of Sponsor or any content, image(s) or material that violates or
            infringes any third party rights of any kind, including without
            limitation, any third party privacy, publicity, trade secret and/or
            intellectual property rights, including third party registered
            and/or common law copyrights and trademarks (the preceding excludes
            the identification of the Post Secondary School where Entrant is
            taking courses in the mental health field);
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not disparage Sponsor, Administrator, any other person or
            entity affiliated with the Contest or products, services or entities
            that are competitive with any of the foregoing;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain content, image(s), watermarks or other creative
            elements not created by and original to Entrant;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain content, images(s) or other material that is
            misleading, inappropriate, indecent, obscene, hateful, tortious,
            defamatory, slanderous or libelous;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain content, image(s) or other material that reflects,
            advocates or promotes bigotry, racism, hatred, harm or exploitation
            of or against any class, group or individual, discrimination based
            on race, gender, gender identity, religion, nationality, ethnicity,
            citizenship status, disability, sexual orientation or age, or
            actions or activities that are restricted, prohibited, illegal or
            unlawful (including without limitation, the consumption of alcohol
            or any controlled substances);
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not contain content, image(s) or other material that is
            unlawful or in violation of or contrary to any applicable federal or
            state laws or regulations;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must not have been previously submitted in a promotion of any kind,
            or published, posted, exhibited or displayed publicly for commercial
            use by any means and in any form or media;
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Must comply with all other applicable state, local, and federal
            laws, rules and regulations.
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        In addition to the foregoing, Essays and Finalist Videos (if applicable)
        cannot:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Contain executable programming of any kind, including without
            limitation, viruses, worms, Trojan horses, spyware, malware, time
            bombs, Easter eggs, cancel bots or any kind of malicious or benign
            computer programming whatsoever; and
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Contain encrypted content of any kind, including without limitation,
            encoded messages.
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        Entrants understand and agree that Sponsor and Administrator have no
        duty of confidentiality or other fiduciary duty with regard to the
        content of their Entries or Finalist Videos (if applicable).
      </p>
      <br />
      <p dir="ltr">
        <b>
          FOR ALL ESSAYS/FINALIST VIDEOS, THE ESSAYS/FINALIST VIDEOS REPRESENT
          SOLELY THE VIEWS/OPINIONS OF THE INDIVIDUAL ENTRANT AND DO NOT REFLECT
          THE VIEWS/OPINIONS OF SPONSOR IN ANY MANNER. SPONSOR EXPRESSLY
          DISCLAIMS ANY AND ALL LIABILITY IN CONJUNCTION WITH SUCH POSTED
          ESSAYS/FINALIST VIDEOS.
        </b>
      </p>
      <br />
      <p dir="ltr" id="essay">
        <b>8. Initial Judging/Finalist Notification:</b>
      </p>
      <p dir="ltr">
        All eligible Entries will be judged by a combined panel of judges from
        Administrator and Sponsor (and their designees) to select up to twenty
        (20) Finalists. The scoring will be based on the weighted criteria
        below, each judged on a scale of 1-10 (10 being the highest) as follows:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Relevance to theme and quality of responses to both Essays (30%)
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              How well does the Entrant use both their personal and academic
              experiences to clearly address both Essay Prompts?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">Are the responses clear and logical? (30%)</p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Does the Entrant express a clear understanding of the mental
              health and wellness needs of the LGBTQIA+ and/or BIPOC communities
              and provide a thoughtful plan of support?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">Originality of responses (30%)</p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Do the responses offer fresh insight and innovative thinking?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">
            Suitability of responses for use by Sponsor for promotional purposes
            (10%)
          </p>
        </li>
      </ul>
      <p dir="ltr">
        <br />
        When the total scores are calculated, the top Entries
        (<b>“Finalists”</b>) will advance to the next phase of judging. Judges
        reserve the right to advance fewer than twenty (20) Finalists' Entries
        to the next stage if, in their sole discretion, they do not receive a
        sufficient number of eligible Entries of sufficient quality.
      </p>
      <br />
      <p dir="ltr">
        The Finalists will be contacted by e-mail and/or phone using the
        information contained in the Entry Form, on or about July 18, 2022.
        Failure to respond to the initial verification notice within three (3)
        days after notification or if such notice is returned as undeliverable
        after two (2) attempts may result in disqualification. Finalists will be
        required to sign and return, within three (3) days of notification (or
        such time period as indicated thereon), an Affidavit of Eligibility
        (collectively,
        <b>"Affidavit"</b>), Liability Release and Indemnification, a Publicity
        Release where allowed by law, the necessary paperwork (as well as any
        additional information needed) for a background check** (<b
          >“Contest Documents”</b
        >). Affidavit will also include a Grant of Rights from potential
        Finalists to Sponsor, as detailed above. If the Affidavit of
        Eligibility, Liability Release and Indemnification, and Publicity
        Release, or any other portions of the Contest Documents are not returned
        to Sponsor within the specified time period, the Finalist may be
        disqualified. If a Finalist is disqualified for any reason, there will
        be no prize of any kind awarded and an alternate Finalist will NOT be
        selected.
      </p>
      <br />
      <p dir="ltr">
        **Finalists understand and agree that confirmation of their eligibility
        as a Finalist and the opportunity to compete for a Grand Prize is
        conditioned upon their agreement to a background check as well as fully
        cooperating with the conduct of same. As part of such background check,
        Finalist must consent to Sponsor/Administrator confirming their status
        as a student in a program in the mental health field at a Post Secondary
        School. Relative to the background check, Finalists understand and agree
        that Sponsor's evaluation of the results of the background check and
        determination of whether or not to confirm their Finalist status and
        compete for a Grand Prize is final and binding and not subject to appeal
        or challenge. For clarity, based on the results of the background check,
        Sponsor may elect not to confirm a Finalist and deny them the
        opportunity to compete for a Grand Prize, in which case Sponsor (as well
        as Administrator and Judges) shall not have any liability to such
        Finalist under any legal theory.
      </p>
      <br />
      <p dir="ltr">
        In the event of a tie, the Entry from among all such tied entries with
        the higher/highest score in the “Originality of responses” criterion
        will be considered a Finalist. In the event a tie remains, an additional
        judge of the Sponsor's choosing will be brought in to reevaluate the
        tied Entries. Decisions of the Administrator, the Judging Panel and any
        additional judges (as applicable) are final and binding in all respects.
      </p>
      <br />
      <p dir="ltr">
        In the event of any dispute concerning the identity of any
        Entrant/Finalist/Winner, the Entry will be deemed submitted by the
        natural person who is the authorized holder of the transmitting e-mail
        account used to submit the Entry <b>"Authorized account holder"</b> is
        defined as the natural person who is assigned to an e-mail address by an
        Internet access provider, online service provider, or other organization
        (e.g., business, educational institution, etc.) that is responsible for
        assigning e-mail addresses for the domain associated with the submitted
        e-mail address.
      </p>
      <br />
      <p dir="ltr">
        <b>9. Finalist Video:</b>
      </p>
      <p dir="ltr">
        In addition to the Contest Documents, each Finalist must submit a video
        (no longer than three [3] minutes in length) answering the question,
        “How will winning the Grand Prize support your individual education and
        career journey?” (<b>“Finalist Video”</b> or <b>“Video”</b>). Finalist
        will have until 11:59 pm ET on August 2, 2022 to submit their Video. If
        a Finalist Video is not submitted within the specified time period, the
        Finalist may be disqualified. If a Finalist is disqualified for any
        reason, there will be no prize of any kind awarded and an alternate
        Finalist will NOT be selected.
      </p>
      <br />
      <p dir="ltr">
        All Videos <b class="underline">must</b> comply with the Content
        Guidelines described in Section 6 as well as the following:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            <b>
              Video file attachment should not be no longer than three (3)
              minutes in length and submitted in one of the following formats:
              MOV, AVI, MP4, MPEG/MPG. M4V or WMV. Total file size must be no
              larger than 500MB. Video resolution must be no lower than
              400x300px.
            </b>
            If a Video exceeds three (3) minutes in total length, Sponsor
            reserves the right, in its sole discretion, to only watch and judge
            the first three (3) minutes of the Video.
          </p>
        </li>
      </ul>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            <b>VIDEO MUST NOT CONTAIN ANY MUSIC.</b>
          </p>
        </li>
      </ul>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Videos must be appropriate for viewing by all audiences.
          </p>
        </li>
      </ul>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Video must be solely the original work product of Finalist, with the
            sole exception of participation by a third party videographer from
            whom Finalist has secured all necessary rights (Do not include
            anyone else in your video).
          </p>
        </li>
      </ul>
      <br />
      <br />
      <p dir="ltr">
        <b>10. Finalist Video Judging/Winner Determination</b>
      </p>
      <p dir="ltr">
        Finalist Videos will be judged by a panel of judges comprised of the
        Sponsor and/or its designees (<b>"Judging Panel"</b>). Judging Panel
        will review each Finalist Video and select ten (10) Grand Prize winners.
        The scoring by each judge will be based on criteria as follows:
      </p>
      <br />
      <ul>
        <li dir="ltr">
          <p dir="ltr">
            Relevance to theme and quality of responses to question asked (40%)
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Does the Entrant clearly and directly explain how winning this
              Grand Prize will support their individual education and career
              journey?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">
            Direct effect winning the Grand Prize will have on the BIPOC and
            LGBTQIA+ communities (40%)
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Does the Entrant show a clear correlation between how winning this
              Grand Prize will support their education and benefit BIPOC and
              LGBTQIA+ communities (upon completing their education)?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">
            Does their overall application (Finalist Video and Essays) have a
            clear and impactful vision for supporting the LGBTQIA+ and/or BIPOC
            communities? (10%)
          </p>
        </li>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Does the Entrant express an overall cohesive sentiment regarding
              the mental health and wellness needs of the LGBTQIA+ and/or BIPOC
              communities in conjuncture with their responses to the Essay
              Prompts?
            </p>
          </li>
        </ul>
        <li dir="ltr">
          <p dir="ltr">
            How suitable is the Finalist Video for promotional use? (10%)
          </p>
        </li>
      </ul>
      <br />
      <p dir="ltr">
        When the total scores are calculated, the ten (10) Finalists with the
        highest final scores from the Judging Panel will be deemed Grand Prize
        Winners.
        <b>
          Upon confirmation, Grand Prize Winners will be required to provide a
          W-9 tax form prior to receiving a prize.
        </b>
        The remaining unselected Finalists will not receive any prize.
      </p>
      <br />
      <p dir="ltr">
        In the event of a tie, the Entry from among all such tied entries with
        the higher/highest score in the “Direct effect winning the Grand Prize
        will have on the BIPOC and LGBTQIA+ communities" criterion will be
        considered a winner. In the event a tie remains, an additional judge of
        the Sponsor's choosing will be brought in to reevaluate the tied
        Finalist Videos. Decisions of the Administrator, the Judging Panel and
        any additional judges (as applicable) are final and binding in all
        respects.
      </p>
      <br />
      <p dir="ltr">
        Entrants/Finalists understand and agree that the results of the Judging
        are in all cases confidential and proprietary information of Sponsor
        and, as such, will not be disclosed. Entrants/Finalists waive any right
        he/she/they may have under any legal theory to review the results of the
        Judging.
      </p>
      <br />
      <p dir="ltr">
        All Judges' decisions are final and binding in all matters relating to
        this Contest. Each Entrant acknowledges that other Entrants, Sponsor's
        employees or independent contractors may have created ideas and concepts
        that may have familiarities or similarities to their Entry and Finalist
        Video (if applicable), and agrees that they will not be entitled to any
        compensation or right to negotiate with the Released Parties because of
        these familiarities or similarities. Notwithstanding anything to the
        contrary in these Contest Rules, Sponsor reserves the right to reject or
        disqualify any Entry or Finalist for any reason or no reason at all, in
        its sole discretion.
      </p>
      <br />
      <p dir="ltr">
        <b>11. Prizes and Approximate Retail Value ("ARV"):</b>
      </p>
      <br />
      <p dir="ltr">
        <b>Grand Prize (Ten [10] Winners):</b> Each Winner will receive ten
        thousand dollars ($10,000) (a <b>“Grand Prize”</b> or <b>“Grant”</b>) to
        be used for tuition (as applicable), awarded in the form of a check in
        the name of the Grand Prize Winner (<b>"Prize"</b>).
      </p>
      <br />
      <p dir="ltr">
        All federal, state, local and other taxes are the sole responsibility of
        each Winner who will each be issued an IRS 1099 Tax Form reflecting the
        value of the Grand Prize. Winner agrees to fully cooperate with Sponsor
        and its representatives as to its compliance with all governmental
        reporting requirements implicated by awarding the Grand Prize to winner.
      </p>
      <br />
      <p dir="ltr">
        SPONSOR MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
        IMPLIED, REGARDING ANY PRIZE OR YOUR PARTICIPATION IN THE CONTEST. BY
        PARTICIPATING IN THE CONTEST, EACH ENTRANT AGREES TO RELEASE AND HOLD
        SPONSOR AND THE EMPLOYEES, OFFICERS, DIRECTORS, SHAREHOLDERS, AGENTS,
        REPRESENTATIVES OF SPONSOR, THEIR AFFILIATES, SUBSIDIARIES, ADVERTISING,
        PROMOTION, AND FULFILLMENT AGENCIES (INCLUDING ADMINISTRATOR AS WELL AS
        THE JUDGES) AND LEGAL ADVISORS, HARMLESS FROM ANY AND ALL LOSSES,
        DAMAGES, RIGHTS, CLAIMS AND ACTIONS OF ANY KIND IN CONNECTION WITH THE
        CONTEST OR RESULTING FROM ACCEPTANCE, POSSESSION, OR USE OF ANY PRIZE,
        INCLUDING WITHOUT LIMITATION, PERSONAL INJURY, DEATH, AND PROPERTY
        DAMAGE, AND CLAIMS BASED ON PUBLICITY RIGHTS, DEFAMATION, OR INVASION OF
        PRIVACY.
      </p>
      <br />
      <p dir="ltr">
        <b>12. Release and Additional Conditions of Participation:</b>
      </p>
      <p dir="ltr">
        By participating, participants agree to be bound by and abide by these
        Official Rules and the decisions of Sponsor and Administrator, which
        shall be final and binding in all respects. Participants agree to
        release and hold harmless Sponsor, Administrator, CSM, Meta Platforms,
        Inc, Twitter, Inc, TikTok Inc., each of their respective parents,
        subsidiaries, affiliated and successor companies, advertising and
        promotion agencies (including the management and representation of
        Influencers as well as such Influencers personally), and each of their
        respective officers, directors, agents, representatives and employees,
        as well as each of their respective successors, representatives and
        assigns (collectively, the "Released Parties") from any and all actions,
        claims, injury, death, loss or damage to person(s) or property arising
        in any manner, directly or indirectly, from participation in this
        Contest and/or acceptance, possession, receipt or use/misuse of any
        prize. By participating, participants also authorize the Sponsor and its
        designees to use the participant's name, voice, likeness, photograph,
        prize information, biographical data and city and state of residence in
        programming, advertising, trade and/or promotional material or on a
        winners' list (if applicable) without compensation or notice, to the
        extent permitted by law. Sponsor is not obligated to use any of the
        above mentioned information or materials, but may do so and may edit
        such information or materials, at Sponsor's sole discretion, without
        obligation or compensation.
      </p>
      <br />
      <p dir="ltr">
        <b>13. Disputes:</b>
      </p>
      <p dir="ltr">
        To the fullest extent permitted by law, Entrant agrees that: (1) any and
        all disputes, claims and causes of action arising out of or connected
        with this Contest or the awarding of the prizes shall be resolved
        individually, without resort to any form of class action, and
        exclusively by the courts within the County of New York, State of New
        York; (2) any and all claims, judgments and awards shall be limited to
        actual out-of-pocket costs incurred, including costs associated with
        entering this Contest, but in no event attorneys' fees; and (3) under no
        circumstances will Entrant be permitted to obtain awards for, and
        Entrant hereby waives all rights to claim, indirect, punitive,
        incidental and consequential damages and any other damages, other than
        for actual out-of-pocket expenses, and any and all rights to have
        damages multiplied or otherwise increased as well as waiving any rights
        to an injunction or other forms of equitable relief. All issues and
        questions concerning the construction, validity, interpretation and
        enforceability of these Official Rules, or the rights and obligations of
        the Entrant and Sponsor in connection with the Contest shall be governed
        by, and construed in accordance with, the laws of the State of New York,
        without giving effect to any choice of law or conflict of law rules
        (whether of the State of New York or any other jurisdiction), which
        would cause the application of the laws of any jurisdiction other than
        the State of New York.
      </p>
      <br />
      <p dir="ltr">
        <b>14. Privacy:</b>
      </p>
      <p dir="ltr">
        Any personally identifiable information collected during an Entrant's
        participation in the Contest will be collected and used by Sponsor and
        its designees for the proper administration and fulfillment of the
        Contest, in accordance with Sponsor's privacy policy available at
        <a href="https://www.hinge.co/privacy/"
          >https://www.hinge.co/privacy/</a
        >
        as otherwise described in these Official Rules.
      </p>
      <br />
      <p dir="ltr">
        Sponsor will not sell, rent, transfer or otherwise disclose your
        personal data to any third party other than as described herein or in
        accordance with Sponsor's privacy policy.
      </p>
      <br />
      <p dir="ltr">
        <b>15. Winners' List:</b>
      </p>
      <p dir="ltr">
        For the names of the winners, mail a self-addressed, stamped envelope
        to: Hinge Mental Health Advocates of Tomorrow Initiative, c/o
        Probability, PO Box 1559, Social Circle, GA 30025-1559. All such
        requests must be received by July 29, 2022. Winners' List will be
        available after August 17, 2022.
      </p>
      <br />
      <p dir="ltr">
        This Contest is in no way sponsored, endorsed or administered by, or
        associated with Facebook, Instagram, Twitter, TikTok or any other social
        media platform. Any questions, comments, or complaints regarding the
        administration of this Contest should be directed to Sponsor and not to
        applicable social media platform. You hereby agree to release Meta
        Platforms, Inc., Twitter, Inc. and TikTok Inc. from any and all
        liability arising from, or in connection with, this Contest.
      </p>
      <br />
    </div>
    <site-footer class="footer"></site-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import SiteFooter from '@/components/Global/SiteFooter.vue';
import HingeMentalHealthHeader from '@/components/Global/HingeMentalHealthHeader.vue';

export default {
  name: 'OfficialRules',
  components: {
    SiteFooter,
    HingeMentalHealthHeader,
  },
};
</script>
<style lang="scss" scoped>
.rules {
  padding: 0 20px;
  margin: 30px 0 28px 0;
  @include respond-to("small") {
    padding: 0 40px;
    margin: 70px auto 196px auto;
    max-width: 1000px;
  }
  .p {
    font-style: italic;
  }
  .text{
    margin: 0 0 40px 0;
      @include respond-to('small'){
        margin: 0 0 32px 0;
      }
    }
  b {
    font-weight: bold;
    font-family: ModernEraBold;
  }
  ul {
    list-style: disc;
    padding-left: 55px;
    ul {
      list-style: circle;
      padding-left: 55px;
    }
  }
  .padding-left {
    padding-left: 35px;
  }
  .underline {
    text-decoration: underline;
  }
  table.dates {
    width: 100%;
    max-width: 700px;
    vertical-align: middle;

    * {
      vertical-align: middle;
    }
    th,
    td {
      border: 1px solid black;
      vertical-align: middle;
      // display: flex;
      // align-items: center;
    }
    th {
      text-decoration: underline;
    }
  }
}
</style>
